import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import { NavLink } from "react-router-dom";
import { logo, logo1 } from "./assets";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./Reg.css";

let styles: any = {
  container: {
    display: "flex",
    width: "100%",
    height: "auto",
    "@media (min-height:200px) and (max-height:656px) ": {
      height: "auto",
    },
    "@media (min-height:657px) and  (max-height:2628px)": {
      height: "100%",
    },
  },
  leftContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:632px)": {
      width: "100%",
    },
  },
  rightContainer: {
    width: "40%",
    height: "auto",
    "@media(max-width:632px)": {
      display: "none",
    },
  },
  button: {
    width: "80%",
    marginBottom: "5%",
    height: "52px",
  },
  leftContainerForm: {
    height: "auto",
    "@media(max-width:632px)": {
      width: "80%",
    },
  },

  textField: {
    width: "79%",
    marginBottom: "5%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
  radio: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginTop: 0,
  },
  radiobtn: {
    display: "flex",
  },
  forgotpassword: {
    marginTop: 0,
    color: "#4d4dc7",
  },
  Registerbutton: {
    width: "81%",
    marginBottom: "3%",
    height: "52px",
    backgroundColor: "#4d4dc7",
    color: "white",
    "&:hover": {
      backgroundColor: "#6868d9",
      color: "white",
    },
  },
  Register: {
    fontStyle: "normal",
    fontWeight: "cold",
    fontFamily: "CormorantGaramond",
    marginBottom: 0,
    marginTop: 0,
  },
  para: {
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: "2px",
    marginTop: "1px",
  },
  divider: {
    display: "flex",
    width: "80%",
    marginBottom: "3%",
  },
  hr: {
    width: "34%",
    marginTop: "3%",
    marginLeft: 0,
    marginRight: 0,
  },
  dividerPara: {
    marginTop: 0,
    fontFamily: "sans-serif",
    color: "dimgrey",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  notReg: {
    display: "inline",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  createAcc: {
    color: "#4d4dc7",
    display: "inline",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  para1: {
    color: "dimgrey",
    marginTop: "0px",
    marginBottom: "8px",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    "@media (min-height:730px)": {
      position: "absolute",
      bottom: 0,
    },
    "@media (min-height:100px) and (max-height:747px)": {
      marginTop: "0px",
    },
  },
  logo1: {
    marginTop: "1%",
    width: "20%",
  },
  mobile: {
    display: "flex",
    gap: "2%",
    marginBottom: "5%",
  },
  countryCode: {
    width: "20%",
  },
  phoneno: {
    width: "57%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
  error: {
    height: "10px",
    color: "red",
    marginTop: "0",
    marginBottom: "12px",
    fontFamily: "Nunito",
    fontSize: "1vw",
  },
  form: {
    marginTop: "4%",
  },
  selectTag: {
    width: "20%",
    height: "44px",
    color: "rgb(129,129,129)",
    border: "1px solid rgb(225,225,225)",
    borderRadius: "4px",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
};

class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
  render() {
    let { classes } = this.props;
    const CHARACTER_LIMIT: any = 13;

    return (
      <div className={classes.container}>
        {/* left div */}

        <div className={classes.leftContainer}>
          <div className={classes.leftContainerForm}>
            <img src={logo1} className={classes.logo1} />
            <h1 className={classes.Register}>Register Now</h1>
            <p className={classes.para}>
              The best investement on earth is earth
            </p>

            <form className={classes.form}>
              <input
                type="text"
                className={classes.textField}
                placeholder="First Name"
                value={this.state.firstName}
                onChange={(e: any) => {
                  this.setState({ firstName: e.target.value });
                }}
              />
              <br />
              <input
                type="text"
                className={classes.textField}
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={(e: any) => {
                  this.setState({ lastName: e.target.value });
                }}
              />
              <input
                type="text"
                className={classes.textField}
                placeholder="Enter Email"
                value={this.state.email}
                onChange={(e: any) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <div className={classes.mobile}>
                <select
                  className={classes.selectTag}
                  value={this.state.countryCodeSelected}
                  onChange={(e: any) => {
                    this.setState({ countryCodeSelected: e.target.value });
                    console.log(e.target.value);
                  }}
                >
                  <option value="">Code</option>
                  {this.state.country_code.map((ele: any) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
                {/* </FormControl> */}
                <input
                  type="text"
                  className={classes.phoneno}
                  placeholder="Mobile No."
                  value={this.state.phone}
                  onChange={(e: any) => {
                    this.setState({ phone: e.target.value });
                  }}
                />
              </div>
              <input
                type="password"
                className={classes.textField}
                placeholder="Password"
                value={this.state.password}
                onChange={(e: any) => {
                  this.setState({ password: e.target.value });
                }}
              />
              <input
                type="password"
                className={classes.textField}
                placeholder="Confirm Password"
                value={this.state.reTypePassword}
                onChange={(e: any) => {
                  this.setState({ reTypePassword: e.target.value });
                }}
              />
            </form>
            <p className={classes.error}>{this.state.errorMessage}</p>
            <Button
              variant="outlined"
              className={classes.Registerbutton}
              onClick={() => {
                this.btnSignUpProps();
              }}
            >
              Sign Up
            </Button>
            <br />
            <p className={classes.notReg}>Already have an account? </p>
            <NavLink
              className={classes.createAcc}
              to="#"
              onClick={this.navigateToLogin}
            >
              Login
            </NavLink>
            <p className={classes.para1}>
              © 2021 ovaluate. All Rights Reserved.
            </p>
          </div>
        </div>

        {/* right div */}
        <div className={classes.rightContainer}>
          <img src={logo} className={classes.img} />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(EmailAccountRegistrationWeb);
