import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { GoogleLogin } from "react-google-login";
import Divider from "@material-ui/core/Divider";
import EmailAccountLoginController from "./EmailAccountLoginController.web";
import { NavLink } from "react-router-dom";
import { logo, logo1 } from "./assets";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Modal from 'react-bootstrap/Modal'

let styles: any = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    // "@media (min-height:100px) and  (max-height:569px)": {
    //   height: "auto",
    // },
    "@media (min-width:1441px) and (max-width:2560px)": {
      height: "100%",
    },
    "@media (min-height:597px)":{
      height:"100%"
    },
    "@media (min-height:100px) and  (max-height:656px)": {
      height: "auto",
    },
  },
  leftContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:632px)": {
      width: "100%",
    },
  },
  rightContainer: {
    width: "40%",
    height: "auto",
    "@media(max-width:632px)": {
      display: "none",
    },
  },
  button: {
    width: "100%",
    marginBottom: "5%",
    height: "52px",
  },
  leftContainerForm: {
    // width: "50%",
    "@media(max-width:632px)": {
      width: "80%",
    },
  },
  // textField: {
  //   width: "100%",
  //   marginBottom: "5%",
    
  // },
  textField: {
    width: "99%",
    marginBottom: "5%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    }
    
  },
  radio: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginTop: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "Bold",
  },
  radiobtn: {
    display: "flex",
  },
  forgotpassword: {
    marginTop: 0,
    color: "#4d4dc7",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  Loginbutton: {
    width: "101%",
    marginBottom: "3%",
    height: "52px",
    backgroundColor: "#4d4dc7",
    color: "white",
    paddingRight: "0",
    paddingLeft: "0",
    '&:hover': {
      backgroundColor: '#6868d9',
      color: 'white',
  },
    
  },
  login: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant SC",
  },
  para: {
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  divider: {
    display: "flex",
    width: "100%",
    marginBottom: "3%",
  },
  hr: {
    width: "34%",
    marginTop: "3%",
    marginLeft: 0,
    marginRight: 0,
  },
  dividerPara: {
    marginTop: 0,
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Nunito",
    color: "dimgrey",
    fontSize: "15px",
  },
  notReg: {
    fontStyle: "normal",
    fontWeight: "Bold",
    fontFamily: "Nunito",
    display: "inline",
  },
  createAcc: {
    color: "#4d4dc7",
    display: "inline",
    fontStyle: "normal",
    fontWeight: "Bold",
    fontFamily: "Nunito",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  para1: {
    color: "dimgrey",
    marginTop: "41%",
    marginBottom: "0",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    "@media (min-height:730px)": {
      position: "absolute",
      bottom: 0,
    },
    // "@media (min-height:100px) and (max-height:747px)": {
    //   marginTop: "5%",
    // },
  },
  logo1: {
    marginTop: "4%",
    width: "26%",
  },
  error:{
    height:"10px",
    color :"red",
    marginTop:"0",
    marginBottom:"12px",
    fontFamily: "Nunito",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    // backgroundColor: palette.background.paper,
    border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
};

class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: any) {
    super(props);
  }
  render() {
    const responseGoogle = (response: any) => {
      console.log(response);
    };

    let { classes } = this.props;

    return (
      <div className={classes.container}>
        {/* <Modal show={this.state.open}>
          <Modal.Header>Hi</Modal.Header>
          <Modal.Body>asdfasdf</Modal.Body>
          <Modal.Footer>This is the footer</Modal.Footer>
        </Modal> */}
        {/* left div */}
        <div className={classes.leftContainer}>
          <div className={classes.leftContainerForm}>
            <img src={logo1} className={classes.logo1} />
            <h1 className={classes.login}>Login</h1>
            <p className={classes.para}>
              See your growth and get consulting support
            </p>
            {/* <GoogleLogin
              clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
              className={classes.button}
            />

            <div className={classes.divider}>
              <Divider variant="middle" className={classes.hr} />
              <p className={classes.dividerPara}>or sign in with</p>
              <Divider variant="middle" className={classes.hr} />
            </div> */}

            <form>
              <input type="text"
                className={classes.textField}
                // id="outlined-password-input"
                // label="Email Address"
                // type="Email"
                placeholder="Email Address"
                // autoComplete="current-password"
                value={this.state.email}
                // variant="outlined"
                onChange={(e: any) => {
                  this.setState({ email: e.target.value });
                }}
              />
              <br />
              <input type="password"
                className={classes.textField}
                // id="outlined-password-input"
                placeholder="Password"
                // label="Password"
                // type="password"
                // autoComplete="current-password"
                // variant="outlined"
                value={this.state.password}
                onChange={(e: any) => {
                  this.setState({ password: e.target.value });
                }}
              />
            </form>
            
            <div className={classes.radio}>
              <div className={classes.radiobtn}>
                <input
                  type="checkbox"
                  checked={this.state.rememberMe}
                  onChange={() => {
                    this.rememberUser(!this.state.rememberMe);
                  }}
                />
                <p className={classes.rememberMe}> Remember me</p>
              </div>
              <>
                <NavLink
                  to="#"
                  className={classes.forgotpassword}
                  onClick={this.navigateToForgotpassword}
                >
                  Forgot Password?
                </NavLink>
              </>
            </div>
            <p className={classes.error} >{this.state.errorMessage}</p>
            <Button
              variant="contained"
              className={classes.Loginbutton}
              onClick={() => {
                this.doEmailLogIn();
              }}
              
            >
              Login
            </Button>
            <br />
            <p className={classes.notReg}>Not registered yet? </p>
            <NavLink
              to="#"
              className={classes.createAcc}
              onClick={this.NavigateToRegister}
            >
              Create an Account
            </NavLink>
            <br />
            <p className={classes.para1}>
            © 2021 ovaluate. All Rights Reserved.
            </p>
          </div>
        </div>

        {/* right div */}
        <div className={classes.rightContainer}>
          <img src={logo} className={classes.img} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EmailAccountLoginBlock);
