import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ValuationEngine2Controller from "./ValuationEngine2Controller.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { logo, bottomIcone } from "./assets.web";
import TextField from "@material-ui/core/TextField";
import "./stepper.css"
// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
    "@media (min-height:875px)": {
      height: "100%",
    },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    "@media (min-width:300px) and (max-width:767px)": {
      width: "100%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "100",
    fontFamily: "Cormorant SC",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "10%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "2%",
    "&:hover": {
      background: "#5438ba",
    },
    marginTop: "5%",
  },
  CancleButton: {
    marginTop: "5%",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "0.8vw",
  },
};

class Step2 extends ValuationEngine2Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents",
        "Review Details",
        "Payment",
        "Repport Type",
      ];
    }

    let purpose_of_valuation = [
      "auction",
      "buy_and_sell",
      "compulsory_purchase_by_gov",
      "insurance",
      "liquidation",
      "projection",
      "purchase",
      "price",
      "allocation",
      "securities_collaterals",
      "mortgage",
    ];

    let property_sub_type = [
      "apartment",
      "cafe",
      "cinema",
      "club",
      "factory",
      "farm",
      "grocery",
      "hospital",
      "hotel",
      "hypermarket",
      "labor_camp",
      "land_developed",
      "land_empty_raw_land",
      "mall",
      "mix_use",
      "museums",
      "office",
      "petrol_station",
      "retail",
      "salon",
      "school",
      "shared_kitchen",
      "showroom",
      "spa",
      "supermarket",
      "trading",
      "villa",
      "warehouse",
      "worship_houses",
    ];

    let property_current_status = [
      "owner",
      "occupied",
      "leased",
      "empty_raw_land",
      "developed_land",
    ];

    let utilities_surrounding = [
      "mall",
      "school",
      "clinic",
      "mosuqe",
      "park",
      "supermarket",
      "restaurant",
      "college",
      "cinema",
      "ride_and_entertainment",
      "highways",
    ];

    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <img
            src={bottomIcone}
            style={{ position: "fixed", right: 0, bottom: 0, width: "15%" }}
          />
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step2</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p className="para" >{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.Category}>Property Details</h1>
              <p className={classes.RightContainerAddProperty}>
                Add property location & landmark to get to know
              </p>
              <form>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      PURPOSE OF VALUATION
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ purposeOfvaluation: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {purpose_of_valuation.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      PROPERTY SUB-TYPE
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ propertyType: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {property_sub_type.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>CURRENT STATUS</label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ currentStatus: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {property_current_status.map((ele) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      UTILITIES SOURROUNDING
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({
                          utilitiesSurroundings: e.target.value,
                        });
                      }}
                    >
                      <option value="">Please choose</option>
                      {utilities_surrounding.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                </div>

                <h1 className={classes.Category} style={{ marginTop: "5%" }}>
                  Financial Details
                </h1>
                <p className={classes.RightContainerAddProperty}>
                  Add property location & landmark to get to know
                </p>

                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      TOTAL INCOME PER ANNUM(IN AED)
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="total income eg: 5,000,000"
                      type="number"
                      value={this.state.totalIncome}
                      onChange={(e) => {
                        this.setState({ totalIncome: e.target.value });
                        // this.setState({ user_type: "agent" })
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      TOTAL EXPENSE PER ANNUM(IN AED)
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="total expense eg: 3,500"
                      type="number"
                      value={this.state.totalExpense}
                      onChange={(e) => {
                        this.setState({ totalExpense: e.target.value });
                        // this.setState({ user_type: "agent" })
                      }}
                    />
                  </div>
                </div>
              </form>
              <p className={classes.errorMessage}>{this.state.errorMessage}</p>
              <Button
                className={classes.CancleButton}
                onClick={() => {
                  // this.setState({ toggle: !this.state.toggle });
                  localStorage.setItem("step", "0");
                  this.props.navigation.navigate("Step1");
                  this.setState({ country_id: "" });
                  this.setState({ city_id: "" });
                  this.setState({ district_id: "" });
                }}
              >
                PREVIOUS STEP
              </Button>
              <Button className={classes.nextButton} onClick={()=>{this.step2()}}>
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Step2);
