import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ValuationEngine2Controller from "./ValuationEngine2Controller.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { logo, bottomIcone, img ,pdf} from "./assets.web";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import CardContent from "@material-ui/core/CardContent"; // import "./ProfileSetup.css";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LinearProgress from '@material-ui/core/LinearProgress';
import "./stepper.css"


let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    "@media (min-height:877px)": {
      height: "100%",
    },
    // border:"1px solid red",
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    marginTop: "9%",
    "@media (min-width:100px) and (max-width:766px)": {
      width: "100%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "100",
    fontFamily: "Cormorant SC",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "10%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    // marginTop:"14%"
    width: "60%",
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "2%",
    "&:hover": {
      background: "#5438ba",
    },
    marginTop: "5%",
  },
  CancleButton: {
    marginTop: "5%",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "50%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "48%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "60%",
  },
  asideContainer1: {
    width: "60%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  cardContainer: {
    height: "50px",
    position: "relative",
    marginTop: "5%",
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "5%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
};

class Step3 extends ValuationEngine2Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents",
        "Review Details",
        "Payment",
        "Repport Type",
      ];
    }

    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <img
            src={bottomIcone}
            style={{ position: "fixed", right: 0, bottom: 0, width: "15%" }}
          />
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step3</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p >{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.Category}>Upload Documents</h1>
              <p className={classes.RightContainerAddProperty}>
                Add property location & landmark to get to know
              </p>
              {/* <form> */}
                <div className={classes.asideContainer}>
                  <Card className={classes.cardContainer}>
                    <input
                      type="file"
                      className={classes.UploadContainer}
                      onChange={(event: any) => {
                        this.handelImage(event);
                        this.setState({ img1: event.target.files[0].name });
                        localStorage.setItem(
                          "img1",
                          event.target.files[0].name
                        );
                      }}
                    />
                    <div className={classes.iconContainer}>
                      <label className={classes.inputLabel}>
                        Upload Plot Map
                      </label>
                      <AddCircleOutlinedIcon
                        style={{ marginTop: "2%", color: "#5438ba" }}
                      />
                    </div>
                  </Card>
                </div>
                {(this.state.progress2!=0 && this.state.progress2<100) && <div>
              <h3
                style={{
                  marginTop: "5%",
                  fontStyle: "normal",
                  fontWeight: 100,
                  fontFamily: "Cormorant SC",
                }}
              >
                UPLOADING
              </h3>
                <div style={{ display: "flex", gap: "2%" }}>
                  <img src={img} />
                  <p className={classes.RightContainerAddProperty}>
                    {this.state.img1}
                  </p>
                  <HighlightOffIcon
                    style={{
                      color: "#5438ba",
                      position: "relative",
                      bottom: "5px",
                    }}
                    onClick={() => {
                      // this.handelRemove();
                    }}
                  />
                  <LinearProgress variant="determinate" style={{width:"60%"}} value={this.state.progress} />
                </div>
              </div>}




              {(this.state.progress2!=0 && this.state.progress2< 100) && <div>
              <h3
                style={{
                  marginTop: "5%",
                  fontStyle: "normal",
                  fontWeight: 100,
                  fontFamily: "Cormorant SC",
                }}
              >
                UPLOADING
              </h3>
              <div>
                  <div style={{ display: "flex", gap: "2%" }}>
                    <img src={img} />
                    <p className={classes.RightContainerAddProperty}>
                      {this.state.img2}
                    </p>
                    <HighlightOffIcon
                      style={{
                        color: "#5438ba",
                        position: "relative",
                        bottom: "5px",
                      }}
                      onClick={(e:any) => {
                        this.handelRemove(e);
                      }}
                    />
                    <LinearProgress variant="determinate" style={{width:"60%"}} value={this.state.progress} />
                  </div>
                </div></div>
                }
             {this.state.progress==100 && <div>
              <h3
                style={{
                  marginTop: "5%",
                  fontStyle: "normal",
                  fontWeight: 100,
                  fontFamily: "Cormorant SC",
                }}
              >
                UPLOADED
              </h3>
              
              {this.state.mapUploaded.length > 0 && (
                this.state.mapUploaded.map((ele:any)=>{
                  return <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                  <img src={img} />
                  <p className={classes.RightContainerAddProperty}  >
                    {ele.name}
                  </p>
                  
                  <HighlightOffIcon
                    style={{
                      color: "#5438ba",
                      position: "relative",
                      bottom: "5px",
                    }}
                    onClick={(e:any,value:any=ele)=>{this.handelRemove(value)}}
                  />
                </div>
                })
                
              )}

              {/* {this.state.img2.length > 0 && (
                <div>
                  <hr />{" "}
                  <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                    <img src={img} style={{marginBottom:"1%"}} />
                    <p className={classes.RightContainerAddProperty}>
                      {this.state.img2}
                    </p>
                    <HighlightOffIcon
                      style={{
                        color: "#5438ba",
                        position: "relative",
                        bottom: "5px",
                      }}
                      onClick={(ele) => {
                        // this.handelRemove2();
                      }}
                    />
                  </div>
                </div>
              // )} */}
              </div>}



              {/* affection plans */}
                <div className={classes.asideContainer1}>
                  <Card className={classes.cardContainer}>
                    <input
                      type="file"
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf/*"
                      className={classes.UploadContainer}
                      onChange={(event: any) => {
                        this.handelImage2(event);
                        this.setState({ img2: event.target.files[0].name });
                        localStorage.setItem(
                          "img2",
                          event.target.files[0].name
                        );
                      }}
                    />
                    <div className={classes.iconContainer}>
                      <label className={classes.inputLabel}>
                        Affection Plan
                      </label>
                      <AddCircleOutlinedIcon
                        style={{ marginTop: "2%", color: "#5438ba" }}
                      />
                    </div>
                  </Card>
                </div>







                {(this.state.progress2!=0 && this.state.progress2< 100) && <div>
              <h3
                style={{
                  marginTop: "5%",
                  fontStyle: "normal",
                  fontWeight: 100,
                  fontFamily: "Cormorant SC",
                }}
              >
                UPLOADING
              </h3>
              <div>
                  <div style={{ display: "flex", gap: "2%" }}>
                    <img src={img} />
                    <p className={classes.RightContainerAddProperty}>
                      {this.state.img2}
                    </p>
                    <HighlightOffIcon
                      style={{
                        color: "#5438ba",
                        position: "relative",
                        bottom: "5px",
                      }}
                      onClick={(e:any) => {
                        // this.handelRemove2(e);
                      }}
                    />
                    <LinearProgress variant="determinate" style={{width:"60%"}} value={this.state.progress} />
                  </div>
                </div></div>
                }
             {this.state.progress2==100 && <div>
              <h3
                style={{
                  marginTop: "5%",
                  fontStyle: "normal",
                  fontWeight: 100,
                  fontFamily: "Cormorant SC",
                }}
              >
                UPLOADED
              </h3>
              
              {this.state.uploadedAffection.length > 0 && (
                this.state.uploadedAffection.map((ele:any)=>{
                  return <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                  <img src={pdf} />
                  <p className={classes.RightContainerAddProperty}  >
                    {ele.name}
                  </p>
                  
                  <HighlightOffIcon
                    style={{
                      color: "#5438ba",
                      position: "relative",
                      bottom: "5px",
                    }}
                    onClick={(e:any,value:any=ele)=>{this.handelRemove2(value)}}
                  />
                </div>
                })
                
              )}

              {/* {this.state.img2.length > 0 && (
                <div>
                  <hr />{" "}
                  <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                    <img src={img} style={{marginBottom:"1%"}} />
                    <p className={classes.RightContainerAddProperty}>
                      {this.state.img2}
                    </p>
                    <HighlightOffIcon
                      style={{
                        color: "#5438ba",
                        position: "relative",
                        bottom: "5px",
                      }}
                      onClick={(ele) => {
                        this.handelRemove2();
                      }}
                    />
                  </div>
                </div>
              )} */}
              </div>}
              {/* </form> */}
              
              
              {/* <input type="file" style={{display:"none"}} ref={fileInput=>this.fileInput=fileInput} ></input>
              <button onClick={()=>{this.fileInput.click()}} >pickup</button> */}
              <p className={classes.errorMessage}>{this.state.errorMessage}</p>
              <Button
                className={classes.CancleButton}
                onClick={() => {
                  // this.setState({ toggle: !this.state.toggle });
                  localStorage.setItem("step", "1");
                  this.props.navigation.navigate("Step2");
                  this.setState({ country_id: "" });
                  this.setState({ city_id: "" });
                  this.setState({ district_id: "" });
                  
                }}
              >
                PREVIOUS STEP
              </Button>
              <Button className={classes.nextButton} onClick={this.step3}>
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Step3);
