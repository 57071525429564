export const firstimg =require("../assets/firstimg.png")
export const logo=require("../assets/logo.svg")
export const profile=require("../assets/profile.png")
export const search=require("../assets/search.png")
export const secondimg=require("../assets/secondimg.png")
export const at =require("../assets/at.png")
export const thirdslide=require("../assets/thirdslide.png")
export const hex1=require("../assets/hex1.png")
export const hex2=require("../assets/hex2.png")
export const hex3=require("../assets/hex3.png")
export const hex4=require("../assets/hex4.png")
export const hex5=require("../assets/hex5.png")
export const hex6=require("../assets/hex6.png")
export const hex7=require("../assets/hex7.png")
export const dots=require("../assets/dots.png")
export const circle=require("../assets/circle.png")
export const human1=require("../assets/human1.png")
export const human2=require("../assets/human2.png")
export const human3=require("../assets/human3.png")
export const spher=require("../assets/sphear.png")
export const human4=require("../assets/human4.png")
export const human5=require("../assets/human5.png")
export const home1=require("../assets/home1.png")
export const home2=require("../assets/home2.png")
export const arrow=require("../assets/arrow.png")
export const facebook=require("../assets/facebook.png")
// export const insta=require("../assets/insta.png")
export const twitter=require("../assets/twitter.png")
// export const youtube=require("../assets/youtube.png")
export const linkdin=require("../assets/linkdin.png")
export const img1=require("../assets/img1.png")
export const img2=require("../assets/img2.png")
export const img3=require("../assets/img3.png")
export const img4=require("../assets/img4.png")
export const img5=require("../assets/img5.png")
export const img6=require("../assets/img6.png")
export const img7=require("../assets/img7.png")
export const img8=require("../assets/img8.png")
export const img9=require("../assets/img9.png")
export const img10=require("../assets/img10.png")
export const img11=require("../assets/img11.png")
export const img12=require("../assets/img12.png")
export const img13=require("../assets/img13.png")
export const img14=require("../assets/img14.png")
export const img15=require("../assets/img15.png")
export const img16=require("../assets/img16.png")
export const img17=require("../assets/img17.png")
export const play=require("../assets/play.png")
export const hexa=require("../assets/hexa.png")