export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo =require("../assets/logo.png")
export const logo1 =require("../assets/logo1.svg")
export const home=require("../assets/home.png")
export const land=require("../assets/land.png")
export const plant=require("../assets/plant.png")
export const resident=require("../assets/resident.png")
export const mix=require("../assets/mix.png")
export const commercial=require("../assets/commercial.png")
export const industrie=require("../assets/industrie.png")
export const bottomIcone=require("../assets/bottomIcone.png")
export const img=require("../assets/img.png")
export const pdf=require("../assets/pdf.png")