import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ValuationEngine2Controller from "./ValuationEngine2Controller.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { logo, bottomIcone } from "./assets.web";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import CardContent from "@material-ui/core/CardContent"; // import "./ProfileSetup.css";
import DoneIcon from "@material-ui/icons/Done";
import "./stepper.css"


let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
    "@media (min-height:875px)": {
      height: "100%",
    },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    "@media (min-width:300px) and (max-width:767px)": {
      width: "100%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "100",
    fontFamily: "Cormorant SC",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "10%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    // marginTop:"12%"
    // marginTop:"14%"
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "3%",
    "&:hover": {
      background: "#5438ba",
    },

    marginTop: "2.9%",
  },
  CancleButton: {
    marginTop: "3%",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "50%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "48%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  cardContainer: {
    height: "50px",
    position: "relative",
    marginTop: "5%",
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "5%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
  CardsContainer: {
    display: "flex",
    width: "100%",
    gap: "3%",
  },
  card: {
    border: "2px solid rgb(234,234,234)",
    width: "30%",
    borderRadius: "10px",
    "&:hover": {
      transform: "scale(1.1)",
      border: "3px solid #5438ba",
    },
  },
  cardData: {
    // width:"90%"
    marginLeft: "10%",
  },
  headding: {
    fontSize: "2vw",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 100,
    marginBottom: 0,
  },
  demo: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "2vh",
    marginTop: 0,
  },
  cost: {
    fontSize: "2vw",
    color: "#5438ba",
    marginBottom: 0,
  },
  previousPrize: {
    color: "grey",
    marginTop: 0,
  },
  offers: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "2vh",
  },
  CheckIconeContainer: {
    display: "flex",
    gap: "4%",
  },
  icon: {
    width: "8%",
    marginTop: "2%",
  },
};

class Step5 extends ValuationEngine2Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents",
        "Review Details",
        "Payment",
        "Repport Type",
      ];
    }

    // let card={
    //   border:"2px solid rgb(234,234,234)",
    //   width:"30%",
    //   borderRadius:"10px",
    //   "&:hover":{
    //     transform:"scale(1.1)",
    //     border:"3px solid #5438ba"
    //   }

    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <img
            src={bottomIcone}
            style={{ position: "fixed", right: 0, bottom: 0, width: "15%" }}
          />
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step5</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p >{label}</p>
                    </StepLabel>
                    <StepContent />
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.Category}>Report Type</h1>
              <p className={classes.RightContainerAddProperty}>
                Add property location & landmark to get to know
              </p>
              <div className={classes.CardsContainer}>
                <div
                  className={classes.card}
                  style={{ border: this.state.container1 }}
                  onClick={() => {
                    this.setState({ container1: "3px solid #5438ba" });
                    this.setState({ container2: "" });
                    this.setState({ container3: "" });
                  }}
                >
                  <div className={classes.cardData}>
                    <h1 className={classes.headding}>Certificate</h1>
                    <p className={classes.demo}>Demo content</p>
                    <p className={classes.cost}>3636.99 AED</p>
                    <p className={classes.previousPrize}>
                      <s>4000.689 AED</s>
                    </p>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation Certificate</p>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.card}
                  style={{ border: this.state.container2 }}
                  onClick={() => {
                    this.setState({ container1: "" });
                    this.setState({ container2: "3px solid #5438ba" });
                    this.setState({ container3: "" });
                  }}
                >
                  <div className={classes.cardData}>
                    <h1 className={classes.headding}>Summary</h1>
                    <p className={classes.demo}>Demo content</p>
                    <p className={classes.cost}>3636.99 AED</p>
                    <p className={classes.previousPrize}>
                      <s>4000.689 AED</s>
                    </p>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation Certificate</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation Summary</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>About the Plot</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Plot Specification</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Property Detail Sheet</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Financial Report</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation</p>
                    </div>
                  </div>
                </div>
                <div
                  className={classes.card}
                  style={{ border: this.state.container3 }}
                  onClick={() => {
                    this.setState({ container1: "" });
                    this.setState({ container2: "" });
                    this.setState({ container3: "3px solid #5438ba" });
                  }}
                >
                  <div className={classes.cardData}>
                    <h1 className={classes.headding}>Detailed</h1>
                    <p className={classes.demo}>Demo content</p>
                    <p className={classes.cost}>3636.99 AED</p>
                    <p className={classes.previousPrize}>
                      <s>4000.689 AED</s>
                    </p>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation Certificate</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation Summary</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>About the Plot</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Plot Specification</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Property Detail Sheet</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Financial Report</p>
                    </div>
                    <div className={classes.CheckIconeContainer}>
                      <DoneIcon className={classes.icon} />
                      <p className={classes.offers}>Valuation</p>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                className={classes.CancleButton}
                onClick={() => {
                  // this.setState({ toggle: !this.state.toggle });
                  localStorage.setItem("step", "3");
                  this.props.navigation.navigate("Step4");
                  this.setState({ country_id: "" });
                  this.setState({ city_id: "" });
                  this.setState({ district_id: "" });
                }}
              >
                PREVIOUS STEP
              </Button>
              <Button className={classes.nextButton}
               onClick={this.saveData1}
               >
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Step5);
