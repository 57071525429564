import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
// import { Props } from "react-select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ForgotPasswordController from "../../forgot-password/src/ForgotPasswordController.web";
import { logo, logo1 } from "./assets";
import { NavLink } from "react-router-dom";
import OTPInputAuthController from "../../otp-input-confirmation/src/OTPInputAuthController.web";
// import * as CryptoJS from 'crypto-js';
export const CryptoJS = require("../../../../node_modules/crypto-js");

let styles: any = {
  container: {
    display: "flex",
    width: "100%",
    "@media (min-height:657px)": {
      height: "100%",
    },
  },
  leftContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:650px)": {
      width: "100%",
    },
  },
  rightContainer: {
    width: "40%",
    height: "auto",
    "@media(max-width:650px)": {
      display: "none",
    },
  },
  button: {
    width: "80%",
    marginBottom: "5%",
    height: "52px",
  },
  leftContainerForm: {
    height: "100%",
    width: "50%",
    "@media (min-width:649px) and (max-width:999px)": {
      width: "80%",
    },
    "@media(max-width:650px)": {
      width: "100%",
    },
  },
  form: {
    marginTop: "20%",
  },

  Otp_textField: {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    marginBottom: "5%",
  },
  radio: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginTop: 0,
  },
  radiobtn: {
    display: "flex",
  },
  forgotpassword: {
    marginTop: 0,
    color: "#4d4dc7",
  },
  Submitbutton: {
    width: "80%",
    marginBottom: "3%",
    height: "52px",
    backgroundColor: "#4d4dc7",
    color: "white",
    "&:hover": {
      backgroundColor: "#6868d9",
      color: "white",
    },
  },
  OtpVerification: {
    // fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "CormorantGaramond",
  },
  para: {
    // fontFamily: "sans-serif",
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  divider: {
    display: "flex",
    width: "80%",
    marginBottom: "3%",
  },
  hr: {
    width: "34%",
    marginTop: "3%",
    marginLeft: 0,
    marginRight: 0,
  },
  dividerPara: {
    marginTop: 0,
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  rememberPassword: {
    ontFamily: "sans-serif",
    display: "inline",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  para1: {
    color: "dimgrey",
    marginBottom: "0px",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    "@media (min-height:615px)": {
      position: "absolute",
      bottom: 0,
    },
    "@media (min-height:100px) and (max-height:615px)": {
      marginTop: "20%",
    },
  },
  logo1: {
    marginTop: "5%",
  },
  changeEmail: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    border: "1px solid #c4c4c4",
    borderRadius: "5px",
  },
  sentEmail: {
    fontFamily: "sans-serif",
    marginLeft: "4%",
  },
  changeBtn: {
    background: "transparent",
    border: "none",
    color: "#4d4dc7",
    fontFamily: "system-ui",
  },
  OTP_bar: {
    width: "22%",
    display: "inline-block",
    textAlign: "center",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "hidden",
    borderBottomColor: "#c4c4c4",
    outline: "none",
    fontSize: "2rem",
  },
  error: {
    height: "10px",
    color: "red",
    marginTop: "0",
    marginBottom: "12px",
    fontFamily: "Nunito",
  },
};

interface props {
  classes: any;
}

class OTPInputAuth extends OTPInputAuthController {
  constructor(props: any) {
    super(props);
  }

  render() {
    const handleChange = (e: any) => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      this.setState({ otp: this.state.otp + e.target.value });
      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 4) {
          // Get the next input field
          const nextSibling: any = document.querySelector(
            `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }
    };

    // let {email}=this.props
    var decrypted = CryptoJS.AES.decrypt(
      localStorage.getItem("email"),
      "Secret Passphrase"
    );

    let { classes } = this.props;

    return (
      <div className={classes.container}>
        {/* left div */}

        <div className={classes.leftContainer}>
          <div className={classes.leftContainerForm}>
            <img src={logo1} className={classes.logo1} />
            <h1 className={classes.OtpVerification}>OTP Verification</h1>
            <p className={classes.para}>We have sent OTP on </p>
            <br />
            <div className={classes.changeEmail}>
              <p className={classes.sentEmail}>
                {decrypted.toString(CryptoJS.enc.Utf8)}
              </p>
              <Button
                onClick={this.navigateToForgotPasswordOTP}
                className={classes.changeBtn}
              >
                Change
              </Button>
            </div>
            <form className={classes.form}>
              <div className={classes.Otp_textField}>
                <input
                  className={classes.OTP_bar}
                  placeholder="0"
                  type="password"
                  autoComplete="current-password"
                  name="ssn-1"
                  maxLength={1}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
                <input
                  className={classes.OTP_bar}
                  placeholder="0"
                  type="password"
                  autoComplete="current-password"
                  name="ssn-2"
                  maxLength={1}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
                <input
                  className={classes.OTP_bar}
                  placeholder="0"
                  type="password"
                  autoComplete="current-password"
                  name="ssn-3"
                  maxLength={1}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
                <input
                  className={classes.OTP_bar}
                  placeholder="0"
                  type="password"
                  autoComplete="current-password"
                  name="ssn-4"
                  maxLength={1}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                />
              </div>
            </form>
            <p className={classes.error}>{this.state.errorMessage}</p>

            <Button
              variant="outlined"
              className={classes.Submitbutton}
              onClick={() => this.submitOtp()}
            >
              Submit OTP
            </Button>
            <p className={classes.para1}>
              © 2021 ovaluate. All Rights Reserved.
            </p>
          </div>
        </div>

        {/* right div */}
        <div className={classes.rightContainer}>
          <img src={logo} className={classes.img} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(OTPInputAuth);
