import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ValuationEngine2Controller from "./ValuationEngine2Controller.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./stepper.css"

import {
  logo,
  land,
  mix,
  resident,
  commercial,
  plant,
  industrie,
  bottomIcone,
} from "./assets.web";
import TextField from "@material-ui/core/TextField";
// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    "@media (min-width:300px) and (max-width:767px)": {
      width: "100%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant SC",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "10%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
    marginBottom:"5%"
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "2%",
    "&:hover": {
      background: "#5438ba",
    },
    // marginBottom: "4%",
  },
  CancleButton: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "97%",
    },
    height: "40px",
    borderRadius: "5px",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "96%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  selectTag1: {
    width: "80%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "82%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "0.8vw",
  },
  propertyImgContainer: {
    border: "2px solid whiteSmoke",
    borderRadius: "10px",
    width: "8.4vw",
    height: "8.4vw",
    textAlign: "center",
    "&:hover": {
      border: "2px solid rgb(84,56,186)",
      transform: "scale(1.1)",
    },
  },
  propertyImgContainer1: {
    border: "2px solid whiteSmoke",
    borderRadius: "10px",
    width: "8.4vw",
    height: "8.4vw",
    textAlign: "center",
    "&:hover": {
      border: "2px solid rgb(84,56,186)",
      transform: "scale(1.1)",
    },
  },
  propertyImgContainer2: {
    border: "2px solid whiteSmoke",
    borderRadius: "10px",
    width: "12.5vw",
    height: "9vw",
    textAlign: "center",
    "&:hover": {
      border: "2px solid rgb(84,56,186)",
      transform: "scale(1.1)",
    },
  },
  propertyImage: {
    width: "40%",
    marginTop: "12%",
  },
  propertyImage2: {
    width: "40%",
    marginTop: "12%",
  },
  formContainer: {
    width: "87%",
    marginTop: "5%",
    "@media (min-width:300px) and (max-width:767px)": {
      width: "100%",
    },
  },
};

class Step1 extends ValuationEngine2Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents",
        "Review Details",
        "Payment",
        "Repport Type",
      ];
    }
    let properties = [
      [resident, "Residential"],
      [commercial, "commercial"],
      [industrie, "Industrial"],
      [mix, "Agricultural"],
    ];
    let properties2 = [[land, "Land"], [plant, "Plant"], [mix, "Mix-Use"]];

    let finishingLevel = [
      "concrete_structure",
      "core_and_shell",
      "semi_finished",
      "fully_finished",
      "fully_finished_high_end",
      "fully_finished_luxurious",
    ];

    let finishing_state = ["new", "excelent", "good", "fair", "worn out"];

    let special_feature = [
      "swimming_pool",
      "smart_home_tech",
      "landscape",
      "covered_parking",
    ];

    let type_of_ownership = [
      "full_ownership",
      "partners",
      "mortgaged",
      "rent_possession",
      "freehold",
    ];
    let type_of_the_building = [
      "concrete_buildings",
      "metal_buildings",
      "wooden_buildings",
    ];
    let geographical_direction_of_the_asset = [
      "north",
      "south",
      "east",
      "west",
    ];


    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <img
            src={bottomIcone}
            style={{ position: "fixed", right: 0, bottom: 0, width: "15%" }}
          />
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Let's start</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p >{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.Category}>Category & Address</h1>
              <p className={classes.RightContainerAddProperty}>
                Add property location & landmark to get to know
              </p>
              <div style={{ width: "90%", display: "flex", gap: "5%" }}>
                <div
                  className={classes.propertyImgContainer1}
                  style={{ border: this.state.resident }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ resident: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "residential" });
                  }}
                >
                  <img src={resident} className={classes.propertyImage} />
                  <p style={{ fontSize: "1vw" }}>{"Residential"}</p>
                </div>
                <div
                  className={classes.propertyImgContainer}
                  style={{ border: this.state.commercial }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ commercial: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "commercial" });
                  }}
                >
                  <img src={commercial} className={classes.propertyImage} />
                  <p style={{ marginTop: "10%", fontSize: "1vw" }}>
                    {"Commercial"}
                  </p>
                </div>
                <div
                  className={classes.propertyImgContainer1}
                  style={{ border: this.state.industrie }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ industrie: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "industrial" });
                  }}
                >
                  <img src={industrie} className={classes.propertyImage} />
                  <p style={{ fontSize: "1vw" }}>{"Industrial"}</p>
                </div>
                <div
                  className={classes.propertyImgContainer1}
                  style={{ border: this.state.agriculture }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ agriculture: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "agricultural" });
                  }}
                >
                  <img src={mix} className={classes.propertyImage} />
                  <p style={{ fontSize: "1vw" }}>{"Agricultural"}</p>
                </div>
              </div>
              <div style={{ display: "flex", gap: "4%", marginTop: "1%" }}>
                <div
                  className={classes.propertyImgContainer2}
                  style={{ border: this.state.land }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ land: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "land" });
                  }}
                >
                  <img src={land} className={classes.propertyImage2} />
                  <p style={{ fontSize: "1vw" }}>{"Land"}</p>
                </div>

                <div
                  className={classes.propertyImgContainer2}
                  style={{ border: this.state.plant }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ plant: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "plant_equipment" });
                  }}
                >
                  <img src={plant} className={classes.propertyImage2} />
                  <p style={{ marginTop: "12%", fontSize: "1vw" }}>{"Plant"}</p>
                </div>

                <div
                  className={classes.propertyImgContainer2}
                  style={{ border: this.state.mix }}
                  onClick={() => {
                    this.selectProperty();
                    this.setState({ mix: "2px solid rgb(84,56,186)" });
                    this.setState({ typeOfasset: "mix_use" });
                  }}
                >
                  <img src={mix} className={classes.propertyImage2} />
                  <p style={{ marginTop: "2%", fontSize: "1vw" }}>
                    {"Mix-Use"}
                  </p>
                </div>
              </div>
              <form className={classes.formContainer}>
                <label className={classes.labelStyle}>COUNTRY</label>
                <br />
                <select
                  className={classes.selectTag1}
                  onChange={(e: any) => {
                    this.selectCountry(e.target.value);
                    this.setState({ country_id: "1" });
                  }}
                >
                  <option value="reset">Country</option>
                  {this.state.country.map((ele: any) => {
                    return (
                      <option
                        onClick={(ele: any) => {
                          this.selectCountry(ele.id);
                        }}
                      >
                        {ele.attributes.name}
                      </option>
                    );
                  })}
                </select>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>City</label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.selectDistrict(e.target.value);
                        this.setState({ city_id: "1" });
                        // this.change(e.target.value)
                      }}
                    >
                      <option value="reset">City</option>
                      {this.state.city.map((ele: any) => {
                        return (
                          <option value={ele.attributes.name}>
                            {ele.attributes.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>DISTRICT</label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ district_id: e.target.value });
                        console.log(e.target.value);
                      }}
                    >
                      <option value={[]}>District</option>
                      {this.state.district.map((ele: any) => {
                        return (
                          <option value={ele.id}>{ele.attributes.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>TITLE DEED NO.</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="eg:437680"
                      type="number"
                      value={this.state.deedno}
                      onChange={(e) => {
                        this.setState({ deedno: e.target.value });
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      MUNICIPALITY NO.
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="eg:437680"
                      type="number"
                      value={this.state.municipalityNo}
                      onChange={(e) => {
                        this.setState({ municipalityNo: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>AREA/DISTRICT</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter area eg:Downtown"
                      type="text"
                      value={this.state.area}
                      onChange={(e) => {
                        this.setState({ area: e.target.value });
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>LANDMARK</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="nearest landmark eg:Burj.."
                      type="text"
                      value={this.state.landmark}
                      onChange={(e) => {
                        this.setState({ landmark: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>PLOT NO.</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter plot no eg:A-004"
                      type="text"
                      value={this.state.plotNo}
                      onChange={(e) => {
                        this.setState({ plotNo: e.target.value });
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      PLOT SIZE SQ FT.
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter plot size eg:10,000"
                      type="number"
                      value={this.state.plotSize}
                      onChange={(e) => {
                        this.setState({ plotSize: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      BUILD UP AREA SQ.FT
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter build up area eg:9000"
                      type="number"
                      value={this.state.buildupArea}
                      onChange={(e) => {
                        this.setState({ buildupArea: e.target.value });
                        // this.setState({ age: e.target.value });
                        // this.setState({ user_type: "agent" });
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>SETBACK</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter setbacks"
                      type="text"
                      value={this.state.setBack}
                      onChange={(e) => {
                        this.setState({ setBack: e.target.value });
                        // this.setState({ age: e.target.value });
                        // this.setState({ user_type: "agent" });
                      }}
                    />
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>ELEVATION</label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="elevation eg:B+G+10"
                      type="text"
                      value={this.state.elevation}
                      onChange={(e) => {
                        this.setState({ elevation: e.target.value });
                        // this.setState({ age: e.target.value });
                        // this.setState({ user_type: "agent" });
                      }}
                    />
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      CONSTRUCTION AGE(in years)
                    </label>
                    <br />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="enter construction age eg:10"
                      type="number"
                      value={this.state.constructionAge}
                      onChange={(e) => {
                        this.setState({ constructionAge: e.target.value });
                        // this.setState({ age: e.target.value });
                        // this.setState({ user_type: "agent" });
                      }}
                    />
                  </div>
                </div>

                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      FINISHING LEVEL OF CONSTRUCTION
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({
                          finishingLevelConstruction: e.target.value,
                        });
                      }}
                    >
                      <option value="">Please choose</option>
                      {finishingLevel.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      FINISHING STATE
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ finishingState: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {finishing_state.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      SPECIAL FEATURES
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ specialFeature: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {special_feature.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      TYPE OF OWNERSHIP
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ typeOfOwenership: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {type_of_ownership.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className={classes.asideTextfield}>
                  <div className={classes.asideContainer}>
                    <label className={classes.labelStyle}>
                      TYPE OF THE BUILDING
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ typeOfBulding: e.target.value });
                      }}
                    >
                      <option value="">Please choose</option>
                      {type_of_the_building.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                  <div className={classes.asideContainer1}>
                    <label className={classes.labelStyle}>
                      GEOGRAPHICAL DIRECTION OF THE ASSIST
                    </label>
                    <br />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({
                          geographicalDirection: e.target.value,
                        });
                      }}
                    >
                      <option value="">Please choose</option>
                      {geographical_direction_of_the_asset.map((ele: any) => {
                        return <option value={ele}>{ele}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </form>
              <p className={classes.errorMessage}>{this.state.errorMessage}</p>
              <Button className={classes.CancleButton} onClick={() => {this.props.navigation.navigate("Dashboard")}}>
                CANCEL
              </Button>
              <Button className={classes.nextButton} onClick={this.step1}>
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Step1);
