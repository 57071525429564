import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ProfileSetupControler from "./ProfileSetupControler";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import { logo,home } from "./assets";
import "./ProfileSetup.css"



let styles: any = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    // border:"solid blue"
    "@media (min-height:100px) and  (max-height:570px)": {
        height: "auto",
      },
      "@media (min-width:100px) and (max-width:767px)":{
        display:"block"
      }
  },
  leftSubContainer: {
    width: "30%",
    background: "#5438ba",
    // border:"solid red"
    "@media (min-width:100px) and (max-width:767px)":{
      display:"none"
    }
  },
  rightSubContainer: {
    width: "70%",
    height: "100%",
    // border:"solid black"
  },
  leftContainerData:{
    marginLeft:"2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant SC",
  },
  addProperty: { 
        color: "white",
        fontFamily: "Nunito",
        fontStyle: "normal",
    },
    RightContainerAddProperty:{
        color: "grey",
        fontFamily: "Nunito",
        fontStyle: "normal",
    },
  button:{
      width:"60%",
      marginBottom:"2%",
      justifyContent:"start",
      border:"1px solid #e0e0e0",
      borderRadius:"5px",
      height:"50px",
      fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight:"100",
        "@media (min-width:100px) and (max-width:767px)":{
          width:"80%"
        },
        "&:focus":{
          backgroundColor:"#5438ba",
          color:"white"
        }
  },
  rightFormContainer:{
      marginLeft:"10%",
      // marginTop:"12%",
      "@media (min-width:100px) and (max-width:767px)":{
        width:"100%"
      }
  },
  nextButton:{
      color:"white",
      background: "#5438ba",
      // marginLeft:"2%",
      "&:hover":{
        background: "#5438ba",
      }
  },
  CancleButton:{
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // width:"90px"
  },
  stepperLabel:{
      color:"white"
  },
  OmranLogo:{
    width:"20%",
    marginTop:"5%"
  },
  homeImg:{
    position:"fixed",
    right:0,bottom:0,
    height:"30%"
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  
};

const steps = [
  {
    label: "Select campaign settings",
    description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: "Create an ad group",
    description:
      "An ad group contains one or more ads which target a shared set of keywords.",
  },
  {
    label: "Create an ad",
    description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
  },
];

class CustomisableUserProfiles extends ProfileSetupControler {
  render() {
    const steps = getSteps();

    function getSteps() {
      return ["Choose type", "Create Profile", "Almost Done"];
    }

    function getStepContent(step: number) {
      switch (step) {
        case 0:
          return `For each .`;
        case 1:
          return "An ad group contains .";
        case 2:
          return `Try out .`;
        default:
          return "Unknown step";
      }
    }

    let { classes } = this.props;
    localStorage.setItem("stepperCount",JSON.stringify(0))

    return (
      <div className={classes.container}>
        <img src={home} className={classes.homeImg}></img>
        {/* left sub container  */}
        <div className={classes.leftSubContainer}>
          <div className={classes.leftContainerData} >
            <img src={logo} className={classes.OmranLogo} />
            <h1 className={classes.LetsStart}>Let's start</h1>
            <p className={classes.addProperty}>
              Add property location & landmark to get to know
            </p>
            <Stepper
              activeStep={this.state.activeStep}
              orientation="vertical"
              style={{ background: "#5438ba" }}
            >
              {steps.map((label, index) => (
                <Step  >
                  <StepLabel ><p >{label}</p></StepLabel>
                  <StepContent>
                    {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        {/* right sub container */}
        <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer} >
                <h1>Category & Address</h1>
                <p className={classes.RightContainerAddProperty}>Add property location & landmark to get to know</p>
            <form className={classes._Form} >
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","agent");this.setState({user_type:"agent"})}}>Agent</Button><br/>
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","land_lord");this.setState({user_type:"land loard"})}}>Land Loard</Button><br/>
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","investors");this.setState({user_type:"investor"})}}>Investors</Button><br/>
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","buyers");this.setState({user_type:"buyer"})}}>Buyers</Button><br/>
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","sellers");this.setState({user_type:"seller"})}}>Seller</Button><br/>
            <Button className={classes.button} onClick={()=>{localStorage.setItem("userType","tenant");this.setState({user_type:"tenant"})}}>Tenant</Button>
            </form>
            <p className={classes.errorMessage}>
                  {this.state.errorMessage}
                </p>
            {/* <Button className={classes.CancleButton}>Cancel</Button> */}
            <Button className={classes.nextButton} onClick={this.navigateToNext} >NEXT STEP</Button>
            </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomisableUserProfiles);
