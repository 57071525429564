import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Props } from "react-select";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ForgotPasswordController from "./ForgotPasswordController.web";
import { NavLink } from "react-router-dom";
import { logo, logo1 } from "./assets";

let styles: any = {
  container: {
    display: "flex",
    width: "100%",
    height: "auto",
    "@media (min-height:100px) and  (max-height:569px)": {
      height: "auto",
    },
    "@media (min-height:597px)":{
      height:"100%"
    },
  },

  leftContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:632px)": {
      width: "100%",
    },
  },
  rightContainer: {
    width: "40%",
    height: "auto",
    "@media(max-width:632px)": {
      display: "none",
    },
  },
  button: {
    width: "80%",
    marginBottom: "5%",
    height: "52px",
  },
  leftContainerForm: {
    width: "50%",
    "@media(max-width:632px)": {
      width: "80%",
    },
  },

  textField: {
    width: "78%",
    marginBottom: "5%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    }
    
  },
  radio: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginTop: 0,
  },
  radiobtn: {
    display: "flex",
  },
  Sendbutton: {
    width: "80%",
    marginBottom: "5%",
    height: "52px",
    backgroundColor: "#4d4dc7",
    color: "white",
    '&:hover': {
      backgroundColor: '#6868d9',
      color: 'white',
  },
  },
  ForgotPassword: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "CormorantGaramond",
  },
  para: {
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  divider: {
    display: "flex",
    width: "80%",
    marginBottom: "5%",
  },
  hr: {
    width: "34%",
    marginTop: "5%",
    marginLeft: 0,
    marginRight: 0,
  },
  dividerPara: {
    marginTop: 0,
    fontFamily: "sans-serif",
  },
  rememberPassword: {
    display: "inline",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  log: {
    color: "#4d4dc7",
    display: "inline",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  para1: {
    color: "dimgrey",
    marginTop: "37%",
    marginBottom: "0",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    "@media (min-height:615px)": {
      position: "absolute",
      bottom: 0,
    },
    "@media (min-height:100px) and (max-height:615px)": {
      marginTop: "20%",
    },
  },
  logo1: {
    marginTop: "5%",
    width: "26%",
  },
  error:{
    height:"10px",
    color :"red",
    marginTop:"0",
    marginBottom:"12px",
    fontFamily: "Nunito",
  }
};

class ForgotPassword extends ForgotPasswordController {
  constructor(props: any) {
    super(props);
  }

  render() {
    let values: any = {
      accountType: "email_account",
      email: this.state.emailValue,
    };

    let { classes } = this.props;

    return (
      <div className={classes.container}>
        {/* left div */}

        <div className={classes.leftContainer}>
          <div className={classes.leftContainerForm}>
            <img src={logo1} className={classes.logo1} />
            <h1 className={classes.ForgotPassword}>Forgotten Password ?</h1>
            <p className={classes.para}>No problem we are here to help you</p>

            <form>
              <input
                className={classes.textField}
                // id="outlined-password-input"
                placeholder="Enter Email"
                type="Email"
                value={this.state.emailValue}
                onChange={(e) => {
                  this.setState({ emailValue: e.target.value });
                }}
              />
              <br />
            </form>
            <p className={classes.error} >{this.state.errorMessage}</p>
            <Button
              variant="outlined"
              className={classes.Sendbutton}
              onClick={() => {
                this.goToOtpAfterEmailValidation(values);
              }}
            >
              Send OTP
            </Button>
            <br />
            <p className={classes.rememberPassword}>Remember Password? </p>
            <NavLink
              to="#"
              className={classes.log}
              onClick={this.navigateToLogin}
            >
              Login
            </NavLink>
            <br />
            <p className={classes.para1}>
              © 2020 yourbrand.com. All Rights Reserved.
            </p>
          </div>
        </div>

        {/* right div */}
        <div className={classes.rightContainer}>
          <img src={logo} className={classes.img} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
