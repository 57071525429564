Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = `bx_block_content_management/property_valuations$`;
exports.exampleAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ValuationEngine2";
exports.labelBodyText = "ValuationEngine2 Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End