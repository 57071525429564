import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import RentDirectltController from "./RentDirectltController.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { home, logo, pdfimg } from "../../Settings2/src/assets.web";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import "./materialuiStyle.css"

// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
    // "@media (min-height:875px)": {
    //   height: "100%",
    // },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    // "@media (min-width:300px) and (max-width:767px)": {
    //   width: "100%",
    // },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  headding: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily: "Cormorant SC",
  },
  addProperty: { color: "white", fontFamily: "Nunito", fontStyle: "normal" },
  nunito: {
    // color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "1vw",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
  },
  nextbutton: {
    //   width:"60.2%",
    // color: "white",
    // background: "rgb(84,56,186)",
    // marginLeft: "1%",
    // "&:hover": {
    //   background: "#5438ba",
    // },
  },
  CancleButton: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
    width: "60%",
  },
  asideTextfieldWidth: {
    width: "98%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "60%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "1%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "60.4%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "50%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
  },
  ImageContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  img: {
    width: "40%",
    marginTop: "7%",
  },
  imgRightContainer: {
    width: "40%",
    position: "relative",
  },
  h1Style: {
    fontSize: "1.9vw",
    position: "absolute",
    top: "36%",
  },
  locationContainer: {
    width: "60%",
    heoght: "10%",
    border: "1px solid rgb(231,231,232)",
    borderRadius: "10px",
  },
  cardContainer: {
    width: "60%",
    height: "50px",
    position: "relative",
    marginTop: 0,
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "3%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
  uploadedImg: {
    display: "flex",
    width: "90%",
    gap: "1%",
  },
  Otp_textField: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    marginBottom: "5%",
  },
  OTP_bar: {
    width: "22%",
    display: "inline-block",
    textAlign: "center",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "hidden",
    borderBottomColor: "#c4c4c4",
    outline: "none",
    fontSize: "2rem",
  },
  uploadImageContainer: {
    border: "1px solid rgb(83,56,185)",
    width: "60%",
    borderRadius: "10px",
    borderStyle: "dashed",
    textAlign: "center",
    background: "rgb(240,239,245)",
  },
  nextBtn: {
    background: "rgb(84,56,186)",
    color: "white",
    '&:hover': {
      backgroundColor: '#6868d9',
      color: 'white',}
  },
  crouselContainer: {
    height:"102px",
    width: "60%",
    marginTop: "5%",
    marginBottom: "5%",
  },
  postByOwner:{
    background: "rgb(84,56,186)",
    color: "white",
    marginLeft: "1%",
    marginBottom: "4%",
    '&:hover': {
        backgroundColor: '#6868d9',
        color: 'white',}
  }
};

class SellStep3 extends RentDirectltController {
  render() {
    const steps = getSteps();
    function getSteps() {
      return ["Category & Address", "Property Details", "Upload Documents"];
    }

    let { classes } = this.props;

    let arr: any = [];

    let HomeType=["apartment", 'cafe', 'cinema', 'club', 'factory', 'farm', 'grocery', 'hospital', 'hotel', 'hypermarket', 'land_developed', 'land_empty_raw_land' ,'mall', 'mix_use', 'museums' ,'office', 'petrol_station', 'retail' ,'salon',  'shared_kitchen', 'showroom', 'spa', 'supermarket', 'trading', 'villa', 'warehouse', 'worship_houses']

    return (
      <>
        <div className={classes.container}>
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step 3</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p className={classes.stepperLabel}>{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.headding}>
                Post For Rent <br />
                By Owner Listing
              </h1>
              <p>SET PRICE</p>
              <input
                className={classes.textField}
                placeholder="eg:437680"
                type="number"
                value={this.state.price}
                onChange={(e)=>{this.setState({price:e.target.value})}}
              />

              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                id="chooseimg"
                onChange={(e: any) => {
                  let file = e.target.files[0];
                  if (file) {
                    // const reader = new FileReader();
                    // reader.onloadend = () => {
                    //   arr = [...this.state.uploadedImages];
                    //   arr.push(reader.result as string);
                    //   console.log(arr, "arr");
                    //   // this.setState({ uploadedImages: arr });
                    // };
                    // reader.readAsDataURL(file);
                    this.handelImage(e)
                  } else {
                    return false;
                  }
                }}
              />
              {/* <p>{this.state.uploadedImages}</p> */}
              <p>PROPERTY PHOTOS</p>
              <div className={classes.uploadImageContainer}>
                <h2 className={classes.headding}>Upload Photo</h2>
                <p className={classes.nunito}>
                  Please upload jpg file with
                  <br /> max 5mb file size
                </p>
                <Button
                  className={classes.nextBtn}
                  style={{ marginBottom: "2%" }}
                  onClick={() => {
                    document.getElementById("chooseimg")?.click();
                  }}
                >
                  CLICK TO UPLOAD
                </Button>
              </div>

              {this.state.uploadedImages.length>0 && <div className={classes.crouselContainer}>
                <Carousel
                  dynamicHeight={true}
                  centerMode={true}
                  centerSlidePercentage={30}
                  autoPlay={false}
                  showStatus={false}
                  showIndicators={false}
                  showThumbs={false}
                >
                  {this.state.uploadedImages.map((ele: any) => {
                    return (
                      <div style={{ height: "100%", marginRight: "2%" }}>
                        <img
                          src={ele.url}
                          style={{ height: "100%", objectFit: "cover" }}
                        />
                        <DeleteOutlineIcon fontSize="small" style={{position:"absolute",top:"2%",right:"4%",background:"whitesmoke",borderRadius:"30px",boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",width:"9%",height:"12%",cursor:"pointer"}} />
                      </div>
                    );
                  })}
                </Carousel>
              </div>}

              <p className={classes.labelStyle}>VIRTUAL TOUR URL</p>
              <input
                className={classes.textField}
                placeholder="eg:437680"
                type="number"
                value={this.state.virtualUrl}
                onChange={(e)=>{this.setState({virtualUrl:e.target.value})}}
              />
              <p className={classes.labelStyle}>HOME FACTS</p>
              <select className={classes.selectTag} onChange={(e)=>{this.setState({HomeType:e.target.value})}} >
                <option value="">Please choose</option>
                {HomeType.map((ele:any)=>{
                  return <option value={ele} >{ele}</option>
                })}
              </select>

              <div className={classes.asideTextfield}>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="No.Of Bedroom"
                    type="number"
                    value={this.state.noOfBedroom}
                    onChange={(e)=>{this.setState({noOfBedroom:e.target.value})}}
                  />
                </div>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="Total Area In Sq.Ft"
                    type="number"
                    value={this.state.areaSqFt}
                    onChange={(e)=>{this.setState({areaSqFt:e.target.value})}}
                  />
                </div>
              </div>
              <div className={classes.asideTextfield}>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="Year Built"
                    type="number"
                    value={this.state.yearBuilt}
                    onChange={(e)=>{this.setState({yearBuilt:e.target.value})}}
                  />
                </div>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="No. Of Bathrooms"
                    type="number"
                    value={this.state.bathroom}
                    onChange={(e)=>{this.setState({bathroom:e.target.value})}}
                  />
                </div>
              </div>
              <div className={classes.asideTextfield}>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="Describe Your Home"
                    type="text"
                    value={this.state.describeHome}
                    onChange={(e)=>{this.setState({describeHome:e.target.value})}}
                  />
                </div>
                <div className={classes.asideContainer}>
                  <input
                    className={classes.asideTextfieldWidth}
                    placeholder="HOA Dues"
                    type="number"
                    value={this.state.HOADues}
                    onChange={(e)=>{this.setState({HOADues:e.target.value})}}
                  />
                </div>
              </div>

              <p className={classes.labelStyle}>CONTACT INFORMATION</p>
              <p
                className={classes.nunito}
                style={{ width: "60%", color: "rgb(126,126,126)" }}
              >
                Potential buyers will contact ypou through the email address you
                use to register on Ovaluate. You must also add your phone number
                to the listing here
              </p>
              <input
                className={classes.textField}
                placeholder="+555 555 555"
                type="number"
                value={this.state.phone}
                onChange={(e)=>{this.setState({phone:e.target.value})}}
              />
              <div style={{ display: "flex", width: "60%", gap: "1.5%" }}>
                <input type="checkbox" style={{cursor:"pointer"}} checked={this.state.termsAndConditions} onClick={()=>{this.setState({termsAndConditions:!this.state.termsAndConditions})}} />
                <p
                  className={classes.nunito}
                  style={{ marginTop: 0, color: "rgb(126,126,126)" }}
                >
                  I Agree To The Following:(1) I Am (Or I Have Authority To Act
                  On Behalf Of) The Owner Of This Home ,(2) I Will Not Provide
                  Incorrect Information Or State A Discriminatory Preference ;
                  And (3) I will Comply With Ovaluate Terms Of Use And Listing
                  Privacy Policy
                </p>
              </div>
              <p className={classes.errorMessage} >{this.state.errorMessage}</p>
              <Button className={classes.CancleButton}>PREV STEP</Button>
              <Button
                className={classes.postByOwner}
                disabled={!this.state.termsAndConditions}
                onClick={()=>{this.step3()}}
                
              >
                POST FOR SALE BY OWNER
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SellStep3);
