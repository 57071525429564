import React, { Component } from "react"
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import SellDirecetelyController from "./SellDirecetelyController.web";
import {sucess} from "./assets.web"




let styles: any = {
    container:{
        height:"100%",
        width:"100%",
        position:"relative",
        // textAlign:"center"
    },
    content:{
        position:"absolute",
        left:"35%",
        top:"20%",
        textAlign:"center",
        width:"30%"
    },
    Button:{
        color:"white",
        background: "#5438ba",
        marginLeft:"2%",
        "&:hover":{
          background: "#5438ba",
        },
        marginBottom:"4%",
        width:"60%"
    },
    heading:{
        // fontStyle: "normal",
        fontWeight: "100",
        fontFamily: "Cormorant SC",
    },
    Para:{
        color:"grey",
        fontFamily: "Nunito",
        fontStyle: "normal",
    },
    img:{
        width:"40%"
    }

}

class Completed extends SellDirecetelyController{

    render(){

        let { classes } = this.props;

        return <div  className={classes.Container}>
                    <div className={classes.content} >
                        <img src={sucess} className={classes.img}></img>
                    <h1 className={classes.heading} >Sucessfully Completed</h1>
                    <p className={classes.Para} >You have Sucessfully updated your Profile </p>
                  <Button className={classes.Button} onClick={()=>{this.props.navigation.navigate("Dashboard")}} >Go To Home</Button>
                  </div>
               </div>
    }
}

export default withStyles(styles)(Completed);