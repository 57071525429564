import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
import axios from "axios"

// Customizable Area Start
import { getTimeZone } from "react-native-localize";
import { number, string } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: number;
  card:any
  container1:any
  container2:any 
  container3:any
  errorMessage:any
  logo:string
  land:any
  agriculture:any
  mix:any
  resident:any
  commercial:any
  plant:any
  industrie:any
  country:any
  district:any
  district_id: string;
  city_id: string;
  country_id: string;
  city: any;
  deedno:any;
  municipalityNo:any;
  area:any;
  landmark:any;
  plotNo:any;
  plotSize:any;
  buildupArea:any;
  setBack:any;
  elevation:any;
  constructionAge:any;
  finishingLevelConstruction:any;
  finishingState:any;
  specialFeature:any;
  typeOfOwenership:any;
  typeOfBulding:any;
  geographicalDirection:any;
  image:any
  mapUploaded:any
  uploadedAffection:any
  countryName:any
      cityName:any
      districtName:any
      purposeOfvaluation:any
      propertyType:any 
      currentStatus:any 
      utilitiesSurroundings:any 
      totalIncome:any 
      totalExpense:any
      typeOfasset:any
      img1:any 
      img2:any
      imgOBj1:any 
      imgObj2:any
      progress:number
      progress2:number
      data:any

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class step4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apiFormCallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      card:true,
      container1:"",
      container2:"",
      container3:"",
      errorMessage:"",
      logo:"",
      land:"",
      mix:"",
      resident:"",
      commercial:"",
      plant:"",
      industrie:"",
      agriculture:"",
      country_id:"",
      country:[],
      district:[],
      district_id:"",
      city_id: "",
      city: [],
      deedno:"",
      municipalityNo:"",
      area:"",
      landmark:"",
      plotNo:"",
      plotSize:"",
      buildupArea:"",
      setBack:"",
      elevation:"",
      constructionAge:"",
      finishingLevelConstruction:"", 
      finishingState:"",           
      specialFeature:"",        
      typeOfOwenership:"",
      typeOfBulding:"",
      geographicalDirection:"",
      image:"",
      countryName:"",
      cityName:"",
      districtName:"",
      purposeOfvaluation:"",
      propertyType:"",
      currentStatus:"", 
      utilitiesSurroundings:"", 
      totalIncome:"",
      totalExpense:"",
      typeOfasset:"",
      img1:"",
      img2:"",
      imgOBj1:"",
      imgObj2:"",
      progress:100,
      progress2:0,
      mapUploaded:[],
      uploadedAffection:[],
      data:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

// setAsset=(value:any)=>{
//   console.log(value)
//   this.setState({assets:})
// }

  nextFields = () => {
  };

  selectProperty=()=>{
    this.setState({land:""})
    this.setState({mix:""})
    this.setState({resident:""})
    this.setState({commercial:""})
    this.setState({plant:""})
    this.setState({industrie:""})
    this.setState({agriculture:""})
  }
  

  navigateToCustomisableUserProfiles = () => {
    localStorage.setItem("stepperCount", JSON.stringify(0));
    this.props.navigation.navigate("CustomisableUserProfiles");
  };

  navigateToNext = () => {
  };

  selectedCountry1:any=""

  selectCountry=(value:any)=>{
      if(value=="reset"){
        this.setState({city:[]})
        this.setState({district:[]})
        this.setState({city_id:""})
        this.setState({district_id:""})
        this.selectedCountry1=value
      }
    else {
      this.setState({city:[]})
      this.setState({district:[]})
      this.setState({city_id:""})
      this.setState({district_id:""})
      this.selectedCountry1=value
    this.setState({country_id:value})
    this.setState({countryName:value})

    const header = {
        token: localStorage.getItem("authToken"),
      };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/cities"
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    }

  }
  
  formData:any = new FormData();
  headers:any={"Content-Type":"multipart/form-data",
      token:localStorage.getItem("authToken")}



   handelImage=(e:any)=>{
    this.formData.append("property_valuation[emirate]",1)
    this.formData.append('property_valuation[plot_maps][]',e.target.files [0],e.target.files[0].name);
    let file=e.target.files[0]
    axios({
      method: 'put',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`,
      data: this.formData,
      headers:this.headers,
      onUploadProgress:ProgressEvent=>{this.setState({progress:Math.round(ProgressEvent.loaded/ProgressEvent.total*100)})}
  })
  .then((response)=>{
    let arr=[...this.state.mapUploaded]
    arr.push(file)
    console.log(arr,"arrrrrrrrrrr")
    localStorage.setItem("plotMaps",JSON.stringify(arr))
    this.setState({mapUploaded:arr})
  })
  .catch((response)=>{
    this.setState({errorMessage:"Please try again"})
  })
  }
  
  
  handelImage2=(e:any)=>{
    if(this.state.img1.length===0){
      this.setState({errorMessage:"Please upload plot map"})
    }

    
    
   
    else{
      console.log(e.target.files,"file at affection")
      // this.formData.append("property_valuation[emirate]",1)
    this.formData.append('property_valuation[affection_plans][]',e.target.files [0],e.target.files[0].name);
    let file=e.target.files[0]
    axios({
      method: 'put',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`,
      data: this.formData,
      headers:this.headers,
      onUploadProgress:ProgressEvent=>{this.setState({progress2:Math.round(ProgressEvent.loaded/ProgressEvent.total*100)})}
  })
  .then((response)=>{
    // localStorage.setItem("updateid",response.data.data.id)
    let arr=[...this.state.uploadedAffection]
    arr.push(file)
    this.setState({uploadedAffection:arr})
  })
  .catch((reaponse)=>{
    this.setState({errorMessage:"Please try again"})
  })}
 
  }

  selectCity=""

  selectDistrict=(value:any)=>{
    this.selectCity=value

    if(value=="reset"){
      this.setState({district:[]})
      this.setState({city_id:""})
      this.setState({district_id:""})
      this.selectedCountry1=value
    }
      
    else{
      this.setState({cityName:value})
        const header = {
            token: localStorage.getItem("authToken"),
          };
    
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        this.apiFormCallId = apiRequest.messageId;
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_content_management/districts"
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(apiRequest.id, apiRequest);
      }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiFormCallId !== null &&
      this.apiFormCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {this.setState({errorMessage:""})
      this.apiFormCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson,"???????????????")
      // if(responseJson?.errors){
      //   console.log("login&&&&&&&&&&&7")
      //   this.setState({errorMessage:"PLease login again"})
      //   return true
      // }
      if(responseJson?.data?.type=="property_valuation"){

        if(responseJson.data?.attributes?.plot_maps && responseJson.data?.attributes?.purpose_of_valuation && responseJson.data?.attributes?.type_of_assets){
          this.setState({data:responseJson.data})
          console.log("//////",responseJson.data);
          this.setState({mapUploaded:responseJson.data.attributes.plot_maps})
          this.setState({uploadedAffection:responseJson.data.attributes.affection_plans})
        }

        else if(responseJson.data?.attributes?.plot_maps){
          localStorage.setItem("stepperCount","3")
          this.props.navigation.navigate("Step4")
        }
        else if(responseJson.data?.attributes?.purpose_of_valuation){
          localStorage.setItem("stepperCount","2")
          this.props.navigation.navigate("Step3")
        }
        else if(responseJson.data?.attributes?.type_of_assets){
          localStorage.setItem("stepperCount","1")
          this.props.navigation.navigate("Step2")
          localStorage.setItem("updateid",responseJson.data.id)
        }
        // console.log(responseJson,"finalvalue")
        // localStorage.removeItem("propertyValuation")
        // localStorage.removeItem("img1")
        // localStorage.removeItem("img2")
        // this.props.navigation.navigate("Step6")
      }
      else if(responseJson?.error){
        // this.setState({errorMessage:"Please try after some time"})
        this.setState({errorMessage:"Please login again"})
      }
      else if(responseJson?.errors){
          // this.setState({errorMessage:"Please try after some time"})
          this.setState({errorMessage:"Please login again"})
        }

      // set country list
      else if(responseJson?.data[0]?.type=="country"){
        // this.showAlert("","Sucessfully Logged in" )
        this.setState({country:responseJson.data})
        this.setState({district:[]})
        
      }


      // set city list
      else if(responseJson?.data[0]?.type=="city"){
        const cities=this.state.country.filter((ele:any)=>{
            if(ele.attributes.name==this.selectedCountry1){
                return ele}
        })  
        this.setState({city:cities[0]?.attributes?.cities})
    }


    // set district list
  else if(responseJson?.data[0]?.type=="district"){
    // this.setState({city_id:responseJson.data})
    
      const district=this.state.city.filter((ele:any)=>{
          if(this.selectCity==ele.attributes.name){return ele}
      })
      this.setState({city_id:district[0]?.attributes?.districts[0]?.attributes?.city_id})
      // console.log(district[0].attributes.districts[0].attributes.city_id,"city_id")
    this.setState({district:district[0]?.attributes?.districts})}


      
    else if(responseJson?.data?.type=="email_account"){
                  this.props.navigation.navigate("SucessfullyCompleted");
    }
    
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log(errorReponse,"?????????////")

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } 
    }

    else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJson);
      if(responseJson?.data?.errors){this.setState({errorMessage:"Something went wrong please try again after some time"})}
      
    }
    this.parseApiCatchErrorResponse(errorReponse);

    // Customizable Area End
  }

  async componentDidMount() {
    // window.scrollTo(0, 0);
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    console.log("scroll")
    let stepper:any=localStorage.getItem("step")
    stepper=Number(stepper)
    this.setState({activeStep:this.state.activeStep+stepper})

    if(localStorage.getItem("propertyValuation")){
      let data:any=localStorage.getItem("propertyValuation")
      data=JSON.parse(data)
      this.setState({city_id:data.city_id})
      this.setState({countryName:data.countryName})
      this.setState({typeOfasset:data.type_of_assets})
      this.setState({landmark:data.landmark})
      this.setState({plotNo:data.plot_no})
      this.setState({cityName:data.cityName})
      this.setState({constructionAge:data.constructionAge})
      this.setState({setBack:data.setback})
      this.setState({area:data.area_district})
      this.setState({elevation:data.elevation})
      this.setState({typeOfBulding:data.type_of_the_building})
      this.setState({purposeOfvaluation:data.purpose_of_valuation})
      this.setState({plotSize:data.plot_size_sq_ft})
      this.setState({buildupArea:data.buildup_area_sq_ft})
      this.setState({totalIncome:data.total_income_per_annum})
      this.setState({currentStatus:data.property_current_status})
      this.setState({totalExpense:data.total_expense_per_annum})
      this.setState({deedno:data.title_deed_no})
      this.setState({municipalityNo:data.municipality_no})
      this.setState({finishingLevelConstruction:data.finishing_level_of_construction})
      this.setState({finishingState:data.finishing_state})
      this.setState({geographicalDirection:data.geographical_direction_of_the_asset})
      this.setState({propertyType:data.property_sub_type})
      this.setState({specialFeature:data.special_feature})
      this.setState({typeOfOwenership:data.type_of_ownership})
      this.setState({utilitiesSurroundings:data.utilities_surrounding})
    }

    const header = {
        token: localStorage.getItem("authToken"),
      };
  
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.apiFormCallId = apiRequest.messageId;
  
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`
    );
  
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );
  
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(apiRequest.id, apiRequest);
  
  }

  saveData1 = () => {
    // let formData:any = new FormData();

    // formData.append("property_valuation[emirate]",1)
    // formData.append("property_valuation[plot_size_sq_ft]",3456)
    // // formdata.append('property_valuation[affection_plans][]',e.target.files [0]);

    //  this.setState({image:formdata})
    // formData.append("emirate",document);
    // let data=formData 

    // let headers:any={"Content-Type":"multipart/form-data",
    //   token:localStorage.getItem("authToken")}

   
      // console.log(this.state.image,"image")

  //   let header={
  //     "Content-Type":"application/json",
  //     token:localStorage.getItem("authToken")
  //   }

  //   const httpBody = {
  //     property_valuation:{
  //               "type_of_assets": this.state.typeOfasset,
  //               "property_sub_type": this.state.propertyType,
  //               "emirate": this.state.city_id,
  //               "utilities_surrounding": this.state.utilitiesSurroundings,
  //               "geographical_direction_of_the_asset": this.state.geographicalDirection,
  //               "purpose_of_valuation": this.state.purposeOfvaluation,
  //               "property_current_status": this.state.currentStatus,
  //               "total_income_per_annum": this.state.totalIncome,
  //               "total_expense_per_annum": this.state.totalExpense,
  //               "property_type": this.state.propertyType,
  //               "country_id": this.state.country_id,
  //               "city_id": this.state.city_id,
  //               "title_deed_no": this.state.deedno,
  //               "municipality_no": this.state.municipalityNo,
  //               "area_district": this.state.area,
  //               "landmark": this.state.landmark,
  //               "plot_no": this.state.plotNo,
  //               "plot_size_sq_ft": this.state.plotSize,
  //               "buildup_area_sq_ft": this.state.buildupArea,
  //               "setback": this.state.setBack,
  //               "elevation": this.state.elevation,
  //               // "address_type": this.state.add,
  //               "finishing_level_of_construction": this.state.finishingLevelConstruction,
  //               "finishing_state": this.state.finishingState,
  //               "special_feature": this.state.specialFeature,
  //               "type_of_ownership": this.state.typeOfOwenership,
  //               "type_of_the_building": this.state.typeOfBulding,
  //               "account_id": 129,
  //               "district_id": this.state.district_id,
  //               "affection_plans":this.state.image,
  //               "countryName":this.state.countryName,
  //               "cityName":this.state.cityName,
  //               "constructionAge":this.state.constructionAge
  //             }
  //                   }

  // // this.props.navigation.navigate("Step4")

  //   const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

  //   this.apiFormCallId = apiRequest.messageId;

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     `bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`
  //   );

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );

  //   apiRequest.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.exampleAPiMethod
  //   );

  //   runEngine.sendMessage(apiRequest.id, apiRequest);

  //   return true;
  this.props.navigation.navigate("Step6")
  }

  step1=()=>{
    if(
      this.state.countryName.length==0||
      this.state.cityName.length==0||
      this.state.district_id.length==0||
      this.state.deedno.length==0||
      this.state.municipalityNo.length==0||
      this.state.area.length==0||
      this.state.landmark.length==0||
      this.state.plotNo.length==0||
      this.state.plotSize.length==0||
      this.state.buildupArea.length==0||
      this.state.setBack.length==0||
      this.state.elevation.length==0||
      this.state.constructionAge.length==0||
      this.state.typeOfasset.length==0||
      this.state.finishingLevelConstruction.length==0||
      this.state.finishingState.length==0||
      this.state.specialFeature.length==0||
      this.state.typeOfOwenership.length==0||
      this.state.typeOfBulding.length==0||
      this.state.geographicalDirection.length==0
    ){   
           this.setState({ errorMessage: "Complete all fields and proceed"});
          return false
        }
        else{

          let header={
            "Content-Type":"application/json",
            token:localStorage.getItem("authToken")
          }

          const httpBody = {
            property_valuation: {
            "type_of_assets": this.state.typeOfasset,
            "property_sub_type": "",
            "emirate": this.state.city_id,
            "utilities_surrounding": "",
            "geographical_direction_of_the_asset": this.state.geographicalDirection,
            "purpose_of_valuation": "",
            "property_current_status": "",
            "total_income_per_annum": "",
            "total_expense_per_annum": "",
            "property_type": this.state.propertyType,
            "country_id": this.state.country_id,
            "city_id": this.state.city_id,
            "title_deed_no": this.state.deedno,
            "municipality_no": this.state.municipalityNo,
            "area_district": this.state.area,
            "landmark": this.state.landmark,
            "plot_no": this.state.plotNo,
            "plot_size_sq_ft": this.state.plotSize,
            "buildup_area_sq_ft": this.state.buildupArea,
            "setback": this.state.setBack,
            "elevation": this.state.elevation,
            "address_type": "",
            "finishing_level_of_construction": this.state.finishingLevelConstruction,
            "finishing_state": this.state.finishingState,
            "special_feature": this.state.specialFeature,
            "type_of_ownership": this.state.typeOfOwenership,
            "type_of_the_building": this.state.typeOfBulding,
            "account_id": 129,
            "district_id": this.state.district_id,
            "affection_plans":this.state.image,
            "countryName":this.state.countryName,
            "cityName":this.state.cityName,
            "constructionAge":this.state.constructionAge
          }
        }
          const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

          this.apiFormCallId = apiRequest.messageId;
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_content_management/property_valuations`
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "post"
          );
      
          runEngine.sendMessage(apiRequest.id, apiRequest);
          localStorage.setItem("propertyValuation",(JSON.stringify(httpBody.property_valuation)))
          localStorage.setItem("step","1")
          // this.props.navigation.navigate("Step2")
        }
  }

  step2=()=>{
    if(
      this.state.purposeOfvaluation.length==0||
      this.state.propertyType.length==0||
      this.state.currentStatus.length==0||
      this.state.utilitiesSurroundings.length==0||
      this.state.totalIncome.length==0||
      this.state.totalExpense.length==0
    ){
      this.setState({ errorMessage: "Complete all fields and proceed"});
          return false
    }
    else{

      let header={
        "Content-Type":"application/json",
        token:localStorage.getItem("authToken")
      }

      const httpBody = {
        property_valuation: {
          "purpose_of_valuation":this.state.purposeOfvaluation,
            "property_sub_type":this.state.propertyType,
            "property_current_status":this.state.currentStatus,
            "utilities_surrounding":this.state.utilitiesSurroundings,
            "total_income_per_annum":this.state.totalIncome,
            "total_expense_per_annum":this.state.totalExpense,
            }
          }
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

      this.apiFormCallId = apiRequest.messageId;
  
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`
      );
  
      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "put"
      );
  
      runEngine.sendMessage(apiRequest.id, apiRequest);
    }
  }

  step3=()=>{
     if (
      this.state.mapUploaded.length==0||
      this.state.uploadedAffection.length==0
      ) {
        this.setState({ errorMessage: "Please select all the required files" });
        return false;
      }
      else if(this.state.progress!=100 && this.state.progress2!=100){
        this.setState({errorMessage:"Please wait file is uploading"})
      }
      else{
      this.props.navigation.navigate("Step4")
  }
  }

  step4=()=>{
    localStorage.setItem("step","4")
    this.props.navigation.navigate("Step5")
  }


  step5=()=>{
    localStorage.setItem("step","5")
    localStorage.removeItem("propertyValuation")
    this.props.navigation.navigate("Step6")
  }

  handelRemove2=(value:any)=>{
    let result:any=this.state.uploadedAffection.filter((ele:any)=>{
      if(ele!=value){return ele}
    })
    let formdata=new FormData()

    for(let i:any=0;i<result.length;i++){
      formdata.append('property_valuation[affection_plans][]',result[i])
    }
    axios({
      method: 'put',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`,
      data: formdata,
      headers:this.headers,
      onUploadProgress:ProgressEvent=>{this.setState({progress:Math.round(ProgressEvent.loaded/ProgressEvent.total*100)})}
  })
  .then((response)=>{
    console.log(response.data,"result affection")
    this.setState({uploadedAffection:result})
  })
  .catch((response)=>{
    this.setState({errorMessage:"Documents are required if required add one more image and then delete"})
  })
    
  }

  handelRemove=(value:any)=>{
    console.log(value,"value")
    let result:any=this.state.mapUploaded.filter((ele:any)=>{
      if(ele!=value){return ele}
    })
    let formdata=new FormData()

    for(let i:any=0;i<result.length;i++){
      formdata.append('property_valuation[plot_maps][]',result[i])
    }
    console.log(result,"lkjhgcfxd")
    axios({
      method: 'put',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`,
      data: formdata,
      headers:this.headers,
      onUploadProgress:ProgressEvent=>{this.setState({progress:Math.round(ProgressEvent.loaded/ProgressEvent.total*100)})}
  })
  .then((response)=>{
    this.setState({mapUploaded:result})
  })
  .catch((response)=>{
    console.log(response)
    this.setState({errorMessage:"Documents are required if required add one more image and then delete"})
  })
  }


  change=(ele:any)=>{
    
  }




  deleteImgAtStep4=(value:any)=>{
    let url=value.url 
    let result=this.state.uploadedAffection.filter((ele:any)=>{
      if(url!=ele.url){return ele}
    })
    console.log(this.state.uploadedAffection,"state");
    console.log(result,"result");
  }

  getName:any=(ele:any)=>{
      let url=ele.url
    // let name=""
    // for(let i:any=ele.url.length-1;i>0;i--){
    //   if(ele.url[i]=="/"){break}
    //   else{
    //     console.log(ele.url[i],"else part")
    //     name+=ele.url[i]+name
    //   }
    // }
    // console.log(name,"get nsame")
    // return name
    let name=""
    for(let i:any=url.length-1;i>=0;i--){
        if(url[i]=="/"){
            break
        }
        else{
            name=url[i]+name
        }
    }
    return name
  }

  deleteimg=(id:any)=>{

    let formdata:any=new FormData()
    formdata.append("image_id",id)
    axios({
      method: 'delete',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/image_destroy/${localStorage.getItem("updateid")}`,
      data: formdata,
      headers:this.headers,
  })
    // axios.delete(`https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/image_destroy/${localStorage.getItem("updateid")}`,formdata,this.headers)
    .then((response)=>{
      if(response.data.data.attributes.plot_maps==null){
        this.setState({mapUploaded:[]})
      }
      else{
        this.setState({mapUploaded:response.data.data.attributes.plot_maps})

      }
    })
    .catch((err)=>{
      this.setState({errorMessage:"Please try after some time"})
    })
  }


  deleteAffection=(id:any)=>{
    console.log("affection",id)

    let formdata:any=new FormData()
    formdata.append("image_id",id)
    axios({
      method: 'delete',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/image_destroy/${localStorage.getItem("updateid")}`,
      data: formdata,
      headers:this.headers,
  })
    .then((response)=>{
      if(response.data.data.attributes.affection_plans==null){
        this.setState({uploadedAffection:[]})
      }
      else{
        console.log(response.data)
        this.setState({uploadedAffection:response.data.data.attributes.affection_plans})

      }
      console.log(response.data)
    })
    .catch((err)=>{
      this.setState({errorMessage:"Please try after some time"})
    })
  }

  // Customizable Area End
}