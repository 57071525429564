Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  exports.errorTitle = "Error";
  
  exports.apiVerifyOtpContentType = "application/json";
  exports.apiVerifyOtpEndPoint =
    "account/accounts/sms_confirmation?pin=";
  
  exports.apiVerifyForgotPasswordOtpEndPoint =
    "forgot_password/otp_confirmation";
  
  exports.apiVerifyOtpMethod = "POST";
  
  exports.errorOtpNotValid = "Please enter 4 digit code.";
  
  exports.errorOtpinvalidToken = "Session expired please re enter your email";
  
  exports.btnTxtSubmitOtp = "Submit otp";
  
  exports.placeHolderOtp = "OTP";
  
  exports.labelInfo =
    "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";
  
  exports.submitButtonColor = "#6200EE";
  