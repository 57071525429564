import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ValuationEngine2Controller from "./ValuationEngine2Controller.web"
// import "./ProfileSetup.css";
import { logo1,home } from "./assets.web";
import Button from "@material-ui/core/Button";
import "./stepper.css"



let styles: any = {
  container:{
    width:"100%",
    height:"100%",
    position:"relative"
  },
  omranLogo:{
    position:"absolute",
    left:"5%",
    top:"5%",
    width:"5%"
  },
  SUbContainer:{
    position:"absolute",
    left:"40%",
    top:"20%"
  },
  button:{
    width:"38%",
    background:"rgb(84,56,186)",
    color:"white",
    "&:hover":{
      background:"rgb(82,52,182)"
    }
  },
  ContainerData:{
    paddingLeft:"3%"
  },
  para1:{
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize:"1.3vw",
    fontWeight:"bold"
  },
  para2:{
    fontFamily: "Nunito",
    fontStyle: "normal",
    color:"grey",
    fontSize:"1vw"
  },
  headdingStyle:{
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  }
};

class Step6 extends ValuationEngine2Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return ["Category & Address", "Property Details", "Upload Documents","Review Details","Payment","Report Type"];
    }

    let { classes } = this.props;

    return (
      <div className={classes.container}>
         <img src={logo1} className={classes.omranLogo}/>
         <div className={classes.SUbContainer} >
           <img src={home} style={{width:"40%"}} />
           <div className={classes.ContainerData} >
            <p className={classes.para1} >Thank you for the property valuation</p>
            <h1 className={classes.headdingStyle}>6040 AED</h1>
            <p className={classes.para2}>Download the report by clicking on the button</p>
            
         <Button className={classes.button} onClick={()=>{localStorage.removeItem("step")}} >DOWNLOAD</Button>
         </div>
         </div>
      </div>
    );
  }
}

export default withStyles(styles)(Step6);









