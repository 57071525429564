import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import axios from "axios"
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";
export const CryptoJS = require("../../../../node_modules/crypto-js");


import Geocode from "react-geocode"
// export const Geocode:any=require("../../../../node_modules/react-geocode")

// Customizable Area Start
import { getTimeZone } from "react-native-localize";
import { number, string } from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start

  // Customizable Area End
}

Geocode.setApiKey("AIzaSyAzBqNP4YfqgSpOBJ3W0FRIzJR_1W5dD5g")

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: number;
  card:any
  container1:any
  container2:any 
  container3:any
  errorMessage:any
  uploadedImages:any
  address:any
  price:any
  virtualUrl:any
  HomeType:any
  noOfBedroom:any
  areaSqFt:any
  yearBuilt:any
  bathroom:any
  describeHome:any
  HOADues:any
  phone:any
  gaddress:any
  city:any
  area:any 
  state:any 
  zoom:any 
  height:any 
  mapPosition:any 
  // lat:any 
  // lng:any 
  markerPosition:any 
  titleDeedsDoc:any
  termsAndConditions:boolean
  emailValue:any
  otp:number
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RentDirectltController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apiFormCallId: any;
  requestEmailOtpCallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      card:true,
      container1:"",
      container2:"",
      container3:"",
      errorMessage:"",
      uploadedImages:[],
      address:"",
      price:"",
      virtualUrl:"",
      HomeType:"",
      noOfBedroom:"",
      areaSqFt:"",
      yearBuilt:"",
      bathroom:"",
      describeHome:"",
      HOADues:"",
      phone:"",
      gaddress:"",
      city:"",
      area:"",
      state:"",
      zoom:15, 
      height:"",
      mapPosition:{
      lat:0,
      lng:0 },
      markerPosition:{
      mlat:0,
      mlng:0,
    },
    titleDeedsDoc:"",
    termsAndConditions:false,
    emailValue:"",
    otp:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }




 
  

  // handelSubmit=()=>{
  //   axios({
  //     method: 'put',
  //     url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/sell_directlies/${localStorage.getItem("updateid1")}`,
  //     data: this.formData,
  //     headers:this.headers,
  // })
  // .then((response)=>{
  //   console.log(response)
  // })
  // .catch((reaponse)=>{
  //   this.setState({errorMessage:"Please try again"})
  // })
  // }
  
  
  


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiFormCallId !== null &&
      this.apiFormCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {this.setState({errorMessage:""})
      this.apiFormCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson,"???????????????")
      // if(responseJson?.errors){
      //   console.log("login&&&&&&&&&&&7")
      //   this.setState({errorMessage:"PLease login again"})
      //   return true
      // }
      if(responseJson?.data?.type=="sell_directly"){
        localStorage.setItem("updateid1",responseJson.data.id)
      }
      else if(responseJson?.error){
        // this.setState({errorMessage:"Please try after some time"})
        this.setState({errorMessage:"Please login again"})
      }
      else if(responseJson?.errors){
          // this.setState({errorMessage:"Please try after some time"})
          this.setState({errorMessage:"Please login again"})
        }


      
    else if(responseJson?.data?.type=="email_account"){
                  this.props.navigation.navigate("SucessfullyCompleted");
    }
    
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log(errorReponse,"?????????////")

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } 
    }

    else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJson);
      if(responseJson?.data?.errors){this.setState({errorMessage:"Something went wrong please try again after some time"})}
      
    }
    this.parseApiCatchErrorResponse(errorReponse);

    // Customizable Area End
  }

  async componentDidMount() {
    // window.scrollTo(0, 0);
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    if (!localStorage.getItem("userCredentials")) {
      this.setState({ emailValue: "" });
    } else {
      let localData: any = localStorage.getItem("userCredentials");
      var decrypted = CryptoJS.AES.decrypt(localData, "Secret Passphrase");
      localData = decrypted.toString(CryptoJS.enc.Utf8);
      let userCredentials: any = localData
        .slice(1, localData.length - 1)
        .split(",");
      let useremail: any = userCredentials[0];
      let password: any = userCredentials[1];
      this.setState({ emailValue: useremail });
    }
    let stepper:any=localStorage.getItem("step")
    stepper=Number(stepper)
    this.setState({activeStep:this.state.activeStep+stepper})

    navigator.geolocation.getCurrentPosition(
      function(position) {
        console.log(position);
      },
      function(error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  saveData1 = () => {
    // let formData:any = new FormData();

    // formData.append("property_valuation[emirate]",1)
    // formData.append("property_valuation[plot_size_sq_ft]",3456)
    // // formdata.append('property_valuation[affection_plans][]',e.target.files [0]);

    //  this.setState({image:formdata})
    // formData.append("emirate",document);
    // let data=formData 

    // let headers:any={"Content-Type":"multipart/form-data",
    //   token:localStorage.getItem("authToken")}

   
      // console.log(this.state.image,"image")

    let header={
      "Content-Type":"application/json",
      token:localStorage.getItem("authToken")
    }

    const httpBody = {
                    }

  // this.props.navigation.navigate("Step4")

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/property_valuations/${localStorage.getItem("updateid")}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;}

    formData1:any=new FormData()
saveAddress=()=>{
  if(this.state.address.length==0){
    this.setState({errorMessage:"Please enter address"})
  }
  else{
    this.formData1.append('rent_directly[address_attributes][country]',this.state.address)

    axios({
      method: 'post',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/sell_directlies`,
      data: this.formData1,
      headers:this.headers,
  })
  .then((response)=>{
    console.log(response)
    localStorage.setItem("updateid1",response.data.data.id)
    localStorage.setItem("step","1")
    this.props.navigation.navigate("SellStep2")
  })
  .catch((reaponse)=>{
    console.log(reaponse,"error res")
    this.setState({errorMessage:"Please try again"})
  })
  }
}







// saving data at step 3



formData:any = new FormData();
  headers:any={"Content-Type":"multipart/form-data",
      token:localStorage.getItem("authToken")}



   handelImage=(e:any)=>{
    this.formData.append('sell_directly[property_photos][]',e.target.files [0]);
    // this.formData.append('sell_directly[address_attributes][floor_and_appartment_number]',3456789)
    let file=e.target.files[0].name
    axios({
      method: 'put',
      url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/sell_directlies/${localStorage.getItem("sell_directlyupdateid")}`,
      data: this.formData,
      headers:this.headers,
    })
  .then((response)=>{
    console.log(response.data)
    let arr=[]
    arr.push(file)
    this.setState({titleDeedsDoc:arr})
    this.setState({uploadedImages:response.data.data.attributes.property_photos})
  })
  .catch((response)=>{
    this.setState({errorMessage:"Please try again"})
  })
   
  }







  

step3=()=>{
  if(this.state.virtualUrl.length==0||
    this.state.price.length==0||
    this.state.HomeType.length==0||
    this.state.noOfBedroom.length==0||
    this.state.bathroom.length==0||
    this.state.areaSqFt.length==0||
    this.state.yearBuilt.length==0||
    this.state.describeHome.length==0||
    this.state.HOADues.length==0){
      this.setState({errorMessage:"Please enter all required fields"})
    }
    else if(this.state.uploadedImages.length==0){
      this.setState({errorMessage:"Please upload property photos"})
    }
  else{
//   axios({
//     method: 'put',
//     url: `https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_content_management/sell_directlies/${localStorage.getItem("updateid1")}`,
//     data: this.formData,
//     headers:this.headers,
// })
// .then((response)=>{
//   console.log(response,"//////axios response")
// })
// .catch((reaponse)=>{
//   this.setState({errorMessage:"Please try again"})
// })

  let header={
    "Content-Type":"application/json",
    token:localStorage.getItem("authToken")
  }

  const httpBody = {
    sell_directly:{
      "set_price": this.state.price,
            "virtual_tour_url": this.state.virtualUrl,
            "home_type": this.state.HomeType,
            "no_of_bedroom": this.state.noOfBedroom,
            "total_area_in_sqft": this.state.areaSqFt,
            "year_built": this.state.yearBuilt,
            "no_of_bathroom": this.state.bathroom,
            "describe_your_home": this.state.describeHome,
            "hoa_dues": this.state.HOADues,
            "phone_number": this.state.phone,
            }
                  }

                  console.log(httpBody,"http body")

// this.props.navigation.navigate("Step4")

  const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

  this.apiFormCallId = apiRequest.messageId;

  apiRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_content_management/sell_directlies/${localStorage.getItem("updateid1")}`
  );

  apiRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  apiRequest.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  apiRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "Put"
  );

  runEngine.sendMessage(apiRequest.id, apiRequest);

  return true;
}
}


handelRemove=(value:any)=>{
  
}




// google map 
onMarkerDragEnd = (event:any) => {
  let lat=event.latLng.lat()
  let lng=event.latLng.lng()

  Geocode.fromLatLng(lat,lng)
  .then((response:any)=>{
    console.log(response)
  })
};



otp_validation=(values: { accountType: string; email: string })=>{
  var encrypted = CryptoJS.AES.encrypt(
    `${this.state.emailValue}`,
    "Secret Passphrase"
  );
  localStorage.removeItem("email")
  localStorage.setItem("email",encrypted)
  //change status to OTP
  const header = {
    "Content-Type": "application/json"
  };
  // const requestMessage = new Message(
  //   getName(MessageEnum.RestAPIRequestMessage)
  // );
  //GO TO REQUEST STATE
  // this.requestEmailOtpCallId = requestMessage.messageId;
  // requestMessage.addData(
  //   getName(MessageEnum.RestAPIResponceEndPointMessage),
  //   "forgot_password/otp"
  // );
  // requestMessage.addData(
  //   getName(MessageEnum.RestAPIRequestHeaderMessage),
  //   JSON.stringify(header)
  // );

  this.setState({
    emailValue: values.email ? values.email : ""
  });

  const data = {
    type: values.accountType ? values.accountType : "email_account",
    attributes: {
      email: values.email ? values.email : ""
    }
  };

  const httpBody = {
    data: data
  };

  // axios.post("https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe//bx_block_forgot_password/otps",httpBody)
  // .then((response)=>{
  //   console.log(response.data.meta.token,"axios response")
  //   localStorage.setItem("otp_token",response.data.meta.token)
  // })
  // .catch((err)=>{
  //   console.log(err)
  // })

  // console.log(httpBody,"httpbodu?????????????????")

  // requestMessage.addData(
  //   getName(MessageEnum.RestAPIRequestMethodMessage),
  //   configJSON.exampleAPiMethod
  // );

  // requestMessage.addData(
  //   getName(MessageEnum.RestAPIRequestBodyMessage),
  //   JSON.stringify(httpBody)
  // );

  // runEngine.sendMessage(requestMessage.id, requestMessage);
  }








  submitOtp=()=> {
    
    if (!this.state.otp || this.state.otp === 0) {
      this.setState({errorMessage:"OTP not vallid"})
      return;
    }

    if (this.state.otp) {
      let formdata:any=new FormData()
      let headers:any={"Content-Type":"multipart/form-data"}

      formdata.append("data[token]",localStorage.getItem("otp_token"))
      formdata.append("data[otp_code]",this.state.otp)
      axios.post("https://omran-86271-ruby.b86271.dev.eastus.az.svc.builder.cafe/bx_block_forgot_password/otp_confirmations",formdata,headers)
      .then((response)=>{
        localStorage.removeItem("otp_token")
        if(response.data.messages[0].otp=='OTP validation success'){
          this.showAlert("","Validation Success" )
        setTimeout(()=>{
          this.props.navigation.navigate("SellStep3")
        },1000)
        }
      })
      .catch((err)=>{
        this.setState({errorMessage:"OTP not vallid"})
      })
    } 
  }

}
  // Customizable Area End

