import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import NavigationBlock from "../../../framework/src/Blocks/NavigationBlock";

// Customizable Area Start
import { getTimeZone } from "react-native-localize";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeStep: number;
  toggle: boolean;
  selectType: string;
  firstName: any;
  lastName: any;
  mobileNumber: any;
  email: string;
  age: string;
  gender: string;
  data: any;
  address: string;
  pinCode: string;
  state_id: string;
  city_id: string;
  country_id: string;
  companyName: string;
  tradeLicense: string;
  assets: string;
  agent_broker_number: string;
  Profession: string;
  Nationality: string;
  errorMessage: string;
  user_type: string;
  country: any;
  district: any;
  city: any;
  selectedCountry:any
  card:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProfileSetupControler extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apiFormCallId: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeStep: 0,
      toggle: false,
      selectType: "",
      firstName: "",
      lastName: "",
      mobileNumber: "",
      email: "Sunil123@gmail.com",
      age: "",
      gender: "",
      address: "",
      pinCode: "",
      state_id: "",
      city_id: "",
      country_id: "",
      companyName: "",
      tradeLicense: "",
      assets: "",
      agent_broker_number: "",
      Profession: "",
      Nationality: "",
      errorMessage: "",
      user_type: "",
      country: [],
      district: [],
      city: [],
      data: [],
      selectedCountry:"",
      card:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

// setAsset=(value:any)=>{
//   console.log(value)
//   this.setState({assets:})
// }

  nextFields = () => {
    let type:any=localStorage.getItem("userType")
    this.setState({user_type:type})
    if(this.state.age.length <2 || this.state.age.length >2 ){
      this.setState({errorMessage:"Please check your age"})
    }
    else if(this.state.address.length == 0 ){
      this.setState({errorMessage:"Please provide your address"})
    }
    else if(this.state.pinCode.length <= 5 || this.state.pinCode.length > 6 ){
      this.setState({errorMessage:"Please enter a valid pincode"})
    }

    else if (
      this.state.firstName.length == 0 ||
      this.state.lastName.length == 0 ||
      this.state.mobileNumber.length == 0 ||
      this.state.age.length == 0 ||
      this.state.gender.length == 0 ||
      this.state.pinCode.length == 0 ||
      this.state.state_id.length == 0 ||
      this.state.city_id.length == 0 ||
      this.state.country_id.length == 0
    ) {
      this.setState({ errorMessage: "Complete all fields and move to the next screen" });
      return false;
    }
    else if(this.state.mobileNumber.length!=10){
      this.setState({errorMessage:"Please provide a valid mobile number"})
    }
    else {
      this.setState({ toggle: !this.state.toggle });
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };

  navigateToCustomisableUserProfiles = () => {
    localStorage.setItem("stepperCount", JSON.stringify(0));
    this.props.navigation.navigate("CustomisableUserProfiles");
  };

  navigateToNext = () => {
    if (this.state.user_type == "agent") {
      localStorage.setItem(
        "stepperCount",
        JSON.stringify(this.state.activeStep + 1)
      );
      this.props.navigation.navigate("ProfileSetup");
    } else if (this.state.user_type !== "agent" && this.state.user_type.length>3) {
      localStorage.setItem(
        "stepperCount",
        JSON.stringify(this.state.activeStep + 1)
      );
      this.props.navigation.navigate("OtherProfileSetup");
    }
  };

  selectedCountry1:any=""

  selectCountry=(value:any)=>{
      if(value=="reset"){
        this.setState({city:[]})
        this.setState({district:[]})
        this.setState({city_id:""})
        this.setState({state_id:""})
        this.selectedCountry1=value
      }
    else {
      this.setState({city:[]})
      this.setState({district:[]})
      this.setState({city_id:""})
      this.setState({state_id:""})
      this.selectedCountry1=value
    this.setState({country_id:value})

    const header = {
        token: localStorage.getItem("authToken"),
      };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/cities"
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    }

  }

  selectCity=""

  selectDistrict=(value:any)=>{
    this.selectCity=value

    if(value=="reset"){
      this.setState({district:[]})
      this.setState({city_id:""})
      this.setState({state_id:""})
      this.selectedCountry1=value
      this.setState({state_id:""})
      this.setState({city_id:""})
    }
      
    else{
        const header = {
            token: localStorage.getItem("authToken"),
          };
    
        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
        this.apiFormCallId = apiRequest.messageId;
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_content_management/districts"
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        apiRequest.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(apiRequest.id, apiRequest);
      }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiFormCallId !== null &&
      this.apiFormCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {this.setState({errorMessage:""})
      this.apiFormCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // if(responseJson?.errors){
      //   console.log("login&&&&&&&&&&&7")
      //   this.setState({errorMessage:"PLease login again"})
      //   return true
      // }
      if(responseJson?.error){
          // this.setState({errorMessage:"Please try after some time"})
          this.setState({errorMessage:"Please login again"})
        }


      // set country list
      else if(responseJson?.data[0]?.type=="country"){
          // this.showAlert("","Sucessfully Logged in" )
          this.setState({country:responseJson.data})
          this.setState({city:[]})
          this.setState({district:[]})
        }


        // set city list
          else if(responseJson?.data[0]?.type=="city"){
            const cities=this.state.country.filter((ele:any)=>{
                if(ele.attributes.name==this.selectedCountry1){
                    return ele}
            })  
            this.setState({country_id:cities[0].id})
            this.setState({city:cities[0]?.attributes?.cities})
        }


        // set district list
      else if(responseJson?.data[0]?.type=="district"){
          const district=this.state.city.filter((ele:any)=>{
              if(this.selectCity==ele.attributes.name){return ele}
          })
          this.setState({city_id:district[0]?.attributes?.districts[0]?.attributes?.city_id})
        this.setState({district:district[0]?.attributes?.districts})}

      
    else if(responseJson?.data?.type=="email_account"){
                  localStorage.removeItem("lasstName")
                  localStorage.removeItem("firstName")
                  localStorage.removeItem("phone")
                  this.props.navigation.navigate("SucessfullyCompleted");
    }
    
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      // console.log(errorReponse,"?????????////")

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } 
    }

    else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJson);
      if(responseJson?.data?.errors){this.setState({errorMessage:"Something went wrong please try again after some time"})}
      
    }
    this.parseApiCatchErrorResponse(errorReponse);

    // Customizable Area End
  }

  async componentDidMount() {
    // token
    this.setState({ firstName: localStorage.getItem("firstName") });
    this.setState({ lastName: localStorage.getItem("lasstName") });
    this.setState({ mobileNumber: localStorage.getItem("phone") });


    const header = {
        token: localStorage.getItem("authToken"),
      };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_content_management/countries"
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
  }

  saveData1 = () => {

    if (
        this.state.firstName.length == 0 ||
        this.state.lastName.length == 0 ||
        this.state.mobileNumber.length == 0 ||
        this.state.age.length == 0 ||
        this.state.gender.length == 0 ||
        this.state.address.length == 0 ||
        this.state.pinCode.length == 0 ||
        this.state.state_id.length == 0 ||
        this.state.city_id.length == 0 ||
        this.state.country_id.length == 0 ||
        this.state.tradeLicense.length==0||
        this.state.companyName.length==0||
        this.state.assets.length==0
      ) {
        this.setState({ errorMessage: "Complete all fields and submit" });
        return false;
      }

    const header = {
      "Content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.mobileNumber,
      age: this.state.age,
      gender: this.state.gender,
      agent_broker_number: this.state.agent_broker_number,
      pincode: this.state.pinCode,
      complete_address: this.state.address,
      city_id: this.state.city_id,
      country_id: this.state.country_id,
      district_id: this.state.state_id,
      user_type: this.state.user_type,
        agent_code: this.state.agent_broker_number,
            profession: this.state.Profession,
            nationality: this.state.Nationality,
            assets: this.state.assets,
            company_name: this.state.companyName,
            trade_licence: this.state.tradeLicense
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      // header:header,
      data: data,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  };



  saveData = () => {
    
    if (
        this.state.firstName.length == 0 ||
        this.state.lastName.length == 0 ||
        this.state.mobileNumber.length == 0 ||
        this.state.age.length == 0 ||
        this.state.gender.length == 0 ||
        this.state.address.length == 0 ||
        this.state.pinCode.length == 0 ||
        this.state.state_id.length == 0 ||
        this.state.city_id.length == 0 ||
        this.state.country_id.length == 0 ||
        this.state.agent_broker_number.length == 0||
        this.state.Profession.length==0||
        this.state.Nationality.length==0||
        this.state.assets.length==0
      ) {
        this.setState({ errorMessage: "Complete all fildes and submit" });
        return false;
      }

    const header = {
      "Content-type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.mobileNumber,
      age: this.state.age,
      gender: this.state.gender,
      agent_broker_number: this.state.agent_broker_number,
      pincode: this.state.pinCode,
      complete_address: this.state.address,
      city_id: this.state.city_id,
      country_id: this.state.country_id,
      district_id: this.state.state_id,
      user_type: this.state.user_type,
    agent_code: this.state.agent_broker_number,
            profession: this.state.Profession,
            nationality: this.state.Nationality,
            assets: this.state.assets,
            company_name: this.state.companyName,
            trade_licence: this.state.tradeLicense
    };
    const data = {
      attributes: attrs,
    };

    const httpBody = {
      // header:header,
      data: data,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiFormCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  };

  // Customizable Area End
}
