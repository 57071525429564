Object.defineProperty(exports, "__esModule", {
    value: true
  });
  
  // Customizable Area Start
  exports.validationApiContentType = "application/json";
  exports.validationApiMethodType = "GET";
  exports.exampleAPiEndPoint = "account_block/accounts";
  exports.exampleAPiMethod = "PATCH";
  exports.exampleApiContentType = "application/json";
  exports.textInputPlaceHolder = "Enter Text";
  exports.labelTitleText = "CustomisableUserProfiles";
  exports.labelBodyText = "CustomisableUserProfiles Body";
  exports.profileSetupAPiMethod="update"
  exports.btnExampleTitle = "CLICK ME";
  // Customizable Area End