import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { logo, logo1 } from "./assets";
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";

let styles: any = {
  container: {
    display: "flex",
    width: "100%",
    "@media (min-height:100px) and  (max-height:600px)":{
      height:"auto"
    },
    "@media (min-height:597px)":{
      height:"100%"
    },
  },
  leftContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media(max-width:650px)":{
      width:"100%"
    }
  },
  rightContainer: {
    width: "40%",
    height: "auto",
    "@media(max-width:650px)":{
      display:"none"
    }
  },
  button: {
    width: "80%",
    marginBottom: "5%",
    height: "52px",
  },
  leftContainerForm: {
    height: "100%",
    width: "50%",
    "@media (orientation:portrait)": {
      width: "62%",
    },
  },
  form: {
    marginTop: "20%",
  },

  textField: {
    width: "78%",
    marginBottom: "5%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    }
    
  },
  radio: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginTop: 0,
  },
  radiobtn: {
    display: "flex",
  },
  Resetbutton: {
    width: "80%",
    marginBottom: "3%",
    height: "52px",
    backgroundColor: "#4d4dc7",
    color: "white",
    '&:hover': {
      backgroundColor: '#6868d9',
      color: 'white',
  },
  },
  ResetPassword: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "CormorantGaramond",
  },
  para: {
    color: "dimgrey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  divider: {
    display: "flex",
    width: "80%",
    marginBottom: "3%",
  },
  hr: {
    width: "34%",
    marginTop: "3%",
    marginLeft: 0,
    marginRight: 0,
  },
  dividerPara: {
    marginTop: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  rememberPassword: {
    display: "inline",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  log: {
    fontFamily: "sans-serif",
    color: "#4d4dc7",
    display: "inline",
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  para1: {
    color: "dimgrey",
    "@media (min-height:615px)": {
      position: "absolute",
      bottom: 0,
    },
    "@media (min-height:100px) and (max-height:615px)": {
      marginTop: "14%",
    },
    marginBottom: "0px",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  logo1: {
    marginTop: "5%",
  },
  changeEmail: {
    display: "flex",
  },
  error:{
    height:"40px",
    width:"80%",
    color :"red",
    marginTop:"0",
    marginBottom:"12px",
    fontFamily: "Nunito",
  }
};

class ResetPassword extends ForgotPasswordController {
  constructor(props: any) {
    super(props);
  }

  render() {
    let { classes } = this.props;
    let value: any = {
      newPassword: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };

    return (
      <div className={classes.container}>
        {/* left div */}

        <div className={classes.leftContainer}>
          <div className={classes.leftContainerForm}>
            <img src={logo1} className={classes.logo1} />
            <h1 className={classes.ResetPassword}>Reset Password</h1>
            <p className={classes.para}>No problem we are here to help you</p>

            <form className={classes.form}>
              <input
                className={classes.textField}
                placeholder="New Password"
                type="Password"
                value={this.state.newPassword}
                onChange={(e) => {
                  this.setState({ newPassword: e.target.value });
                }}
              />
              <br />
              <input 
                className={classes.textField}
                placeholder="Confirm Password"
                type="Password"
                value={this.state.confirmPassword}
                onChange={(e) => {
                  this.setState({ confirmPassword: e.target.value });
                }}
              />
            </form>
            {this.state.resetPasswordError?.length>2 && <p className={classes.error} >{this.state.resetPasswordError}</p>}
            <Button
              variant="outlined"
              className={classes.Resetbutton}
              onClick={() => {
                this.goToConfirmationAfterPasswordChange(value);
              }}
            >
              Set Password
            </Button>
            <p className={classes.para1}>
              © 2021 ovaluate. All Rights Reserved.
            </p>
          </div>
        </div>

        {/* right div */}
        <div className={classes.rightContainer}>
          <img src={logo} className={classes.img} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPassword);
