import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import SellWithAgentController from "./SellWithAgent.web.controller";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import { home, logo, pdfimg } from "../../Settings2/src/assets.web";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import "./materialuiStyle.css";
import Slider from "@material-ui/core/Slider";
// start changes
// import Rating from '@material-ui/lab/Rating';
import ReactStars from "react-rating-stars-component";



// import { Rating } from 'react-simple-star-rating'

// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    "@media (min-height:1313px)": {
      height: "100%",
    },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    // "@media (min-width:300px) and (max-width:767px)": {
    //   width: "100%",
    // },
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  headding: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily: "Cormorant SC",
  },
  addProperty: { color: "white", fontFamily: "Nunito", fontStyle: "normal" },
  nunito: {
    // color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  nunito1: {
    // color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "1vw",
    fontWeight: "bold",
    "&:hover":{
        cursor:"pointer"
    }
  },
  nunito3:{
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "0.9vw",
    color:"dimgrey"
    // fontWeight: "bold",
    // "&:hover":{
    //     cursor:"pointer"
    // }
  },
    RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
  },
  nextbutton: {
    //   width:"60.2%",
    // color: "white",
    // background: "rgb(84,56,186)",
    // marginLeft: "1%",
    // "&:hover": {
    //   background: "#5438ba",
    // },
  },
  CancleButton: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
    width: "60.8%",
  },
  asideTextfieldWidth: {
    width: "98%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "60.8%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    marginTop: "1%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "60.8%",
    },
    "&:focus": {
        outline: "2px solid hsl(230,44%,50%)",
      },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "50%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1vw",
  },
  ImageContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  img: {
    width: "40%",
    marginTop: "7%",
  },
  imgRightContainer: {
    width: "40%",
    position: "relative",
  },
  h1Style: {
    fontSize: "1.9vw",
    position: "absolute",
    top: "36%",
  },
  locationContainer: {
    width: "60%",
    heoght: "10%",
    border: "1px solid rgb(231,231,232)",
    borderRadius: "10px",
  },
  cardContainer: {
    width: "60%",
    height: "50px",
    position: "relative",
    marginTop: 0,
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "3%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
  uploadedImg: {
    display: "flex",
    width: "90%",
    gap: "1%",
  },
  Otp_textField: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    marginBottom: "5%",
  },
  OTP_bar: {
    width: "22%",
    display: "inline-block",
    textAlign: "center",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "hidden",
    borderBottomColor: "#c4c4c4",
    outline: "none",
    fontSize: "2rem",
  },
  uploadImageContainer: {
    border: "1px solid rgb(83,56,185)",
    width: "60%",
    borderRadius: "10px",
    borderStyle: "dashed",
    textAlign: "center",
    background: "rgb(240,239,245)",
  },
  nextBtn: {
    background: "rgb(84,56,186)",
    color: "white",
    "&:hover": {
      backgroundColor: "#6868d9",
      color: "white",
    },
  },
  crouselContainer: {
    height: "100%",
    width: "100%",
    marginTop: "5%",
    marginBottom: "5%",
  },
  postByOwner: {
    background: "rgb(84,56,186)",
    color: "white",
    marginLeft: "1%",
    marginBottom: "4%",
    "&:hover": {
      backgroundColor: "#6868d9",
      color: "white",
    },
  },
};

class SellWithAgentStep3 extends SellWithAgentController {
  render() {
    const steps = getSteps();

    function getSteps() {
      return ["Category & Address", "Property Details", "Upload Documents"];
    }

    let { classes } = this.props;

    let arr: any = [];

    let HomeType = [
      "apartment",
      "cafe",
      "cinema",
      "club",
      "factory",
      "farm",
      "grocery",
      "hospital",
      "hotel",
      "hypermarket",
      "land_developed",
      "land_empty_raw_land",
      "mall",
      "mix_use",
      "museums",
      "office",
      "petrol_station",
      "retail",
      "salon",
      "shared_kitchen",
      "showroom",
      "spa",
      "supermarket",
      "trading",
      "villa",
      "warehouse",
      "worship_houses",
    ];

    return (
      <>
        <div className={classes.container}>
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step 3</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p className={classes.stepperLabel}>{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.headding}>
                Sell Traditionally <br />
                With An Agent
              </h1>
              <input
                className={classes.textField}
                placeholder="323,Devi Nagar, Jaipur"
                type="textx"
                value={this.state.price}
                onChange={(e) => {
                  this.setState({ price: e.target.value });
                }}
              />

              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                id="chooseimg"
                onChange={(e: any) => {
                  let file = e.target.files[0];
                  if (file) {
                    // const reader = new FileReader();
                    // reader.onloadend = () => {
                    //   arr = [...this.state.uploadedImages];
                    //   arr.push(reader.result as string);
                    //   console.log(arr, "arr");
                    //   // this.setState({ uploadedImages: arr });
                    // };
                    // reader.readAsDataURL(file);
                    this.handelImage(e);
                  } else {
                    return false;
                  }
                }}
              />
              {/* <p>{this.state.uploadedImages}</p> */}
              <div
                className={classes.textField}
                style={{width:"60.5%"}}
                placeholder="323,Devi Nagar, Jaipur"
              ><p>agent</p></div>

              <div className={classes.asideTextfield}>
              <select
                className={classes.selectTag}
                onChange={(e) => {
                  this.setState({ HomeType: e.target.value });
                }}
              >
                <option value="">Home Type</option>
                {HomeType.map((ele: any) => {
                  return <option value={ele}>{ele}</option>;
                })}
              </select>
              <select
                className={classes.selectTag}
                onChange={(e) => {
                  this.setState({ serviceNeeded: e.target.value });
                }}
              >
                <option value="">Service Needed</option>
                {HomeType.map((ele: any) => {
                  return <option value={ele}>{ele}</option>;
                })}
              </select>
              </div>
              <p className={classes.nunito}>PRICE RANGE</p>
              <div style={{ display: "flex" }}>
                <p className={classes.nunito}>${this.state.range[0]}-</p>
                <p className={classes.nunito}>${this.state.range[1]}</p>
              </div>

              <Slider
                value={this.state.range}
                onChange={this.handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                style={{ width: "60%" ,marginBottom:"5%"}}
                // getAriaValueText={this.valuetext}
                max={2000000}
              /><br/>
              <Button className={classes.CancleButton}>PREV STEP</Button>
              <Button
                className={classes.postByOwner}
                // disabled={!this.state.termsAndConditions}
                // onClick={()=>{this.step3()}}
              >
                NEXT STEP
              </Button>
{/* featured agents */}
<div style={{ display: "flex" }}>
                <h3 className={classes.nunito}>Featured Agents</h3>
                <p
                  style={{ position: "relative", left: "70%", }}
                  className={classes.nunito1}
                >
                  See all
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2%",
                  width: "98%",
                  height: "auto",
                }}
              >
                {this.state.uploadedImages.map((ele: any) => {
                  return (
                    <div style={{ width: "35%", height: "200px", gap: "2%" }}>
                      <img
                        src={ele}
                        style={{
                          width: "100%",
                          height: "66%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <p
                        className={classes.nunito}
                        style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        Joe Smith
                      </p>
                      <p style={{ marginTop: 0, marginBottom: 0 }} className={classes.nunito3}>
                        +555 555 555
                      </p>
                      <div style={{display:"flex"}}>
                      <ReactStars value={3} size={25} />
                      <p className={classes.nunito3} style={{marginTop:"11px"}} >(1.3k)</p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* other agents */}
              <div style={{ display: "flex" }}>
                <p className={classes.nunito}>Other Agents</p>
                <p
                  style={{ position: "relative", left: "72%" }}
                  className={classes.nunito1}
                >
                  See all
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "2%",
                  width: "98%",
                  height: "auto",
                }}
              >
                {this.state.uploadedImages.map((ele: any) => {
                  return (
                    <div style={{ width: "35%", height: "200px", gap: "2%",marginBottom:"5%" }}>
                      <img
                        src={ele}
                        style={{
                          width: "100%",
                          height: "66%",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      <p
                        className={classes.nunito}
                        style={{ marginTop: 0, marginBottom: 0 }}
                      >
                        Joe Smith
                      </p>
                      <p style={{ marginTop: 0, marginBottom: 0 }} className={classes.nunito3}>
                        +555 555 555
                      </p>
                      <div style={{display:"flex"}}>
                      <ReactStars value={3} size={25} />
                      <p className={classes.nunito3} style={{marginTop:"11px"}} >(1.3k)</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button className={classes.CancleButton} onClick={()=>{this.props.navigation.navigate("RentDirectlyStep2");localStorage.setItem("step","1")}}>PREV STEP</Button>
              <Button
                className={classes.postByOwner}
                // disabled={!this.state.termsAndConditions}
                // onClick={()=>{this.step3()}}
              >
                POST FOR SALE BY OWNER  
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SellWithAgentStep3);
