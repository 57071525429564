import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import SellDirecetelyController from "./SellDirecetelyController.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { home ,logo} from "./assets.web";
import TextField from "@material-ui/core/TextField";
// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
    // "@media (min-height:875px)": {
    //   height: "100%",
    // },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    // "@media (min-width:300px) and (max-width:767px)": {
    //   width: "100%",
    // },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  headding: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily: "Cormorant SC",
  },
  addProperty:{color: "white",
  fontFamily: "Nunito",
  fontStyle: "normal",},
  nunito: {
    // color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize:"1vw"
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
 textField: {
    width: "60%",
    marginBottom: "5%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    }
    
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
  },
  button: {
      width:"60.2%",
    color: "white",
    background: "#5438ba",
    marginLeft: 0,
    "&:hover": {
      background: "#5438ba",
    },
    marginTop: 0,
  },
  CancleButton: {
    marginTop: "5%",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "0.8vw",
  },
  ImageContainer:{
      display:"flex",
    justifyContent:"space-around"
  },
  img:{
    width: "40%",
    marginTop:"7%"
  },
  imgRightContainer:{
      width:"40%",
      position:"relative",

  },
  h1Style:{
      fontSize:"1.9vw",
      position:"absolute",
      top:"36%"

  }
};

class SellDirecetely extends SellDirecetelyController {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents"
      ];
    }

    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step 1</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p className={classes.stepperLabel}>{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
            <h1 className={classes.headding} >Sell Your Home <br/>Directly To Ovaluate</h1>
            <label className={classes.nunito} >Address</label><br/>
          <input type="text"
                className={classes.textField}
                placeholder="Please enter"
                value={this.state.address}
                onChange={(e)=>{this.setState({address:e.target.value})}}
              /><br/>
              <p className={classes.errorMessage}>
                  {this.state.errorMessage}
                </p>
              <Button className={classes.button} onClick={()=>{this.saveAddress()}} >Get Your Offer</Button>
              <div className={classes.ImageContainer} >
              <img
                    src={home}
                    className={classes.img}
                />
                <div className={classes.imgRightContainer} >
                    <h1 className={classes.headding} style={{fontSize:"1.9vw",marginTop:"38%",marginBottom:0}}>Why Sell Treditionally</h1><br/>
                    <p  style={{marginTop:0,marginBottom:0}} className={classes.nunito}>Skip Staging And Showing</p><br/>
                    <p style={{marginTop:0,marginBottom:0}} className={classes.nunito}>No Repairs To Manage</p>
                </div>
              </div>
              <div className={classes.ImageContainer} style={{marginTop:"10%"}} >
                <div className={classes.imgRightContainer} >
                    <h1 className={classes.headding} style={{fontSize:"1.9vw",marginTop:"38%",marginBottom:0}} >How To Sell Sell Treditionally</h1><br/>
                    <p style={{marginTop:0,marginBottom:0}} className={classes.nunito} >Request An Ofer To Check Your Home Eligibility,You'll Get Initial Numbers And Secdule A Free Elivation To Finalize Your Offer . If You Decide To Accept ,You'll Close On The Day Of Your Choice</p>
                </div>
                <img
                    src={home}
                    className={classes.img}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SellDirecetely);
