import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import step4Controller from "./step4.webController";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { logo, bottomIcone, img,pdf } from "./assets.web";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import CardContent from "@material-ui/core/CardContent"; // import "./ProfileSetup.css";
import "./stepper.css"
import HighlightOffIcon from "@material-ui/icons/HighlightOff";



let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightContainer: {
    width: "70%",
    // height: "100%",
    "@media (min-width:300px) and (max-width:767px)": {
      width: "100%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "CCormorant Garamond",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Nunito",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
    fontWeight: "bold",
  },
  textField: {
    width: "60%",
    marginBottom: "1%",
    height: "10%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    // marginTop:"14%"
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "2%",
    "&:hover": {
      background: "#5438ba",
    },
    marginTop: "5%",
  },
  CancleButton: {
    marginTop: "5%",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "50%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "48%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  cardContainer: {
    height: "50px",
    position: "relative",
    marginTop: "5%",
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "5%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
  rightSubContainer: {
    width: "80%",
    // border:"1px solid red",
    marginTop: "11%",
    marginLeft: "10%",
  },
  rightSubContainer1: {
    width: "80%",
    // border:"1px solid red",
    marginTop: "5%",
    marginLeft: "10%",
  },
  firstGrid: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    // marginBottom:"10%"
  },
  gridContainer: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.1vw",
    marginBottom: "3%",
  },
  ContainerValues: {
    fontSize: "1vw",
    color: "black",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    marginTop: 0,
  },
};

class Step4 extends step4Controller {
  render() {
    const steps = getSteps();

    function getSteps() {
      return [
        "Category & Address",
        "Property Details",
        "Upload Documents",
        "Review Details",
        "Payment",
        "Repport Type",
      ];
    }

    let { classes } = this.props;
    


    // let categoryData=[{"CATEGORY":"Residential"},{LANDMARK:"U A E"},{LANDMARK:"Burj khalifa"},{"PLOT NUMBER":14},{CITY:"Abu Dhabi"},{"CONSTRUCTION AGE":123},{SETBACK:3214},{AREA:"Down town"},{ELEVATION:"232323"}]

    return (
      <>
        <div className={classes.container}>
          <img
            src={bottomIcone}
            style={{ position: "fixed", right: 0, bottom: 0, width: "15%" }}
          />
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step4</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p >{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightContainer}>
            <div className={classes.rightSubContainer}>
              <h1>Review Details</h1>
              <p className={classes.RightContainerAddProperty}>
                Add property location & landmark to get to know
              </p>
              <p className={classes.Category}>CATEGORY & ADDRESS</p>
              <div className={classes.firstGrid}>
                <div>
                  <p className={classes.gridContainer}>CATEGORY</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.type_of_assets}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>COUNTRY</p>
                  <p className={classes.ContainerValues}>
                    {this.state.countryName}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>LANDMARK</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.landmark}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>PLOT NUMBER</p>
                  <p className={classes.ContainerValues}>{this.state.data?.attributes?.plot_no}</p>
                </div>
                <div>
                  <p className={classes.gridContainer}>CITY</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.plot_size_sq_ft}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>CONSTRUCTION AGE</p>
                  <p className={classes.ContainerValues}>
                    {this.state.constructionAge}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>SETBACKS</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.setback}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>AREA</p>
                  <p className={classes.ContainerValues}>{this.state.data?.attributes?.area_district}</p>
                </div>
                <div>
                  <p className={classes.gridContainer}>ELEVATION</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.elevation}
                  </p>
                </div>
              </div>
            </div>

            <div className={classes.rightSubContainer1}>
              <hr />
              <p className={classes.Category}>PROPERTY DETAILS</p>
              <div className={classes.firstGrid}>
                <div>
                  <p className={classes.gridContainer}>TYPE OF PROPERTY</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.type_of_the_building}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>PURPOSE OF VALUATION</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.purpose_of_valuation}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>PLOT SIZE SQ.FT</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.plot_size_sq_ft}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>BUILD UP AREA SQ.FT</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.buildup_area_sq_ft}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>SETBACKS</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.setback}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>TOTAL INCOME PER ANUM</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.total_income_per_annum}
                  </p>
                </div>
                <div>
                  <p className={classes.gridContainer}>STATUS</p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.property_current_status}
                  </p>
                </div>
                {/* <div ><p className={classes.gridContainer}>NO. UNITS</p><p className={classes.ContainerValues}>0</p></div> */}
                <div>
                  <p className={classes.gridContainer}>
                    TOTAL EXPENSE PER ANUM
                  </p>
                  <p className={classes.ContainerValues}>
                    {this.state.data?.attributes?.total_expense_per_annum}
                  </p>
                </div>
              </div>
            </div>
            <div className={classes.rightSubContainer1}>
              <hr />
              {/* <div style={{ display: "flex", gap: "2%", marginTop: "5%" }}>
                <img src={img} style={{objectFit:"contain"}} />
                <p className={classes.RightContainerAddProperty}>
                  {localStorage.getItem("img1")}
                </p>
              </div>
              <div style={{ display: "flex", gap: "2%", marginTop: "5%" }}>
                <img src={img} style={{objectFit:"contain"}}/>
                <p className={classes.RightContainerAddProperty}>
                  {localStorage.getItem("img2")}
                </p>
              </div> */}
              {this.state.mapUploaded.length > 0 && (
                this.state.mapUploaded.map((ele:any)=>{
                  return <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                  <img src={img} style={{objectFit:"contain"}} />
                  <p className={classes.RightContainerAddProperty}  >
                  {this.getName(ele)}
                  </p>
                  
                  <HighlightOffIcon
                    style={{
                      color: "#5438ba",
                      position: "relative",
                      bottom: "5px",
                    }}
                    onClick={(e:any,value:any=ele)=>{this.deleteimg(ele.id)}}
                  />
                </div>
                })
                
              )}
              {this.state.uploadedAffection && (
                this.state.uploadedAffection.map((ele:any)=>{
                  console.log(ele)
                  return <div style={{ display: "flex", gap: "2%",marginBottom:"1%" }}>
                    <img src={pdf} style={{objectFit:"contain"}} />
                    <p className={classes.RightContainerAddProperty}  >
                    {this.getName(ele)}
                  </p>
                  <HighlightOffIcon
                    style={{
                      color: "#5438ba",
                      position: "relative",
                      bottom: "5px",
                    }}
                    onClick={(e:any,value:any=ele)=>{this.deleteAffection(ele.id)}}
                  />
                  </div>
                })
              )
                }
              
            </div>
            <div style={{ marginLeft: "10%", marginBottom: "5%" }}>
              <Button
                className={classes.CancleButton}
                onClick={() => {
                  // this.setState({ toggle: !this.state.toggle });
                  localStorage.setItem("step", "2");
                  this.props.navigation.navigate("Step3");
                  this.setState({ country_id: "" });
                  this.setState({ city_id: "" });
                  this.setState({ district_id: "" });
                }}
              >
                PREVIOUS STEP
              </Button>
              <Button className={classes.nextButton} onClick={this.step4}>
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(Step4);
