// import { IBlock } from "../../../framework/src/IBlock";
// import { Message } from "../../../framework/src/Message";
// import { BlockComponent } from "../../../framework/src/BlockComponent";
// import MessageEnum, {
//   getName
// } from "../../../framework/src/Messages/MessageEnum";
// import { runEngine } from "../../../framework/src/RunEngine";

// // Customizable Area Start
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// // Customizable Area End

// export const configJSON = require("./config");

// export interface Props {
//   navigation: any;
//   id: string;
//   classes:any,
  
//   // Customizable Area Start
//   // Customizable Area End
// }

// interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
//   activeStep:any;
//   toggle:boolean;
//   selectType:string;
  
  
//   // Customizable Area Start
//   firstName:string;
//   lastName:string;
//   mobileNumber:string;
//   email:string;
//   age:string;
//   gender:string;
//   address:string;
//   pinCode:string;
//   state:string;
//   city:string;
//   country:string;
//   companyName:string;
//   tradeLicense:string;
//   assets:string;
//   AgentCode:any;
//   Profession:any;
//   Nationality:any;
//   // Customizable Area End
// }

// interface SS {
//   id: any;
  
//   // Customizable Area Start
//   // Customizable Area End
// }

// export default class CustomisableUserProfilesController extends BlockComponent<
//   Props,
//   S,
//   SS
// > {

//   // Customizable Area Start
 

//   // Customizable Area End

//   constructor(props: Props) {
//     super(props);
//     this.receive = this.receive.bind(this);

//     // Customizable Area Start
//     this.subScribedMessages = [
//       getName(MessageEnum.AccoutLoginSuccess),
//       // Customizable Area Start
//       // Customizable Area End
//     ];

//     this.state = {
//       txtInputValue: "",
//       txtSavedValue: "A",
//       enableField: false,
//       activeStep:0,
//       toggle:false,
//       selectType:"",
//       firstName:"",
//       lastName:"",
//       mobileNumber:"",
//       email:"",
//       age:"",
//       gender:"",
//       address:"",
//       pinCode:"",
//       state:"",
//       city:"",
//       country:"",
//       companyName:"",
//       tradeLicense:"",
//       assets:"",
//       AgentCode:"",
//       Profession:"",
//       Nationality:"",
//       // Customizable Area Start
//       // Customizable Area End
//     };
//     runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

//     // Customizable Area Start
//     // Customizable Area End

//   }

//   async componentDidMount() {
//     super.componentDidMount();
//     if(localStorage.getItem("stepperCount")){
//       let count:any=localStorage.getItem("stepperCount")
//       this.setState({activeStep:JSON.parse(count)})
//     }
//     else{this.setState({activeStep:0})}
//   }

//   navigateToCustomisableUserProfiles=()=>{
//     // let count:any=localStorage.getItem("stepperCount")
//     // count=count-1
//     localStorage.setItem("stepperCount",JSON.stringify(0))
//     this.props.navigation.navigate("CustomisableUserProfiles")
//   }

//   // navigateToSucessfullyCompleted=()=>{
//   //   let count:any=localStorage.getItem("stepperCount")
//   //   count=count+1
//   //   localStorage.setItem("stepperCount",(count))
//   //   this.props.navigation.navigate("SucessfullyCompleted")
//   // }


//   navigateToNext=()=>{
//     if(this.state.selectType=="agent"){
//       localStorage.setItem("stepperCount",JSON.stringify(this.state.activeStep+1))
//       this.props.navigation.navigate("ProfileSetup")
//     }
//     else if(this.state.selectType=="other"){
//       localStorage.setItem("stepperCount",JSON.stringify(this.state.activeStep+1))
//       this.props.navigation.navigate("OtherProfileSetup")

//     }
//   }

//   async receive(from: string, message: Message) {

//     runEngine.debugLog("Message Recived", message);

//     if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
//       let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

//       this.showAlert(
//         "Change Value",
//         "From: " + this.state.txtSavedValue + " To: " + value
//       );

//       this.setState({ txtSavedValue: value });
//     }

//     // Customizable Area Start
//     // Customizable Area End

//   }

//   txtInputWebProps = {
//     onChangeText: (text: string) => {
//       this.setState({ txtInputValue: text });
//     },
//     secureTextEntry: false
//   };

//   txtInputMobileProps = {
//     ...this.txtInputWebProps,
//     autoCompleteType: "email",
//     keyboardType: "email-address"
//   };

//   txtInputProps = this.isPlatformWeb()
//     ? this.txtInputWebProps
//     : this.txtInputMobileProps;

//   btnShowHideProps = {
//     onPress: () => {
//       this.setState({ enableField: !this.state.enableField });
//       this.txtInputProps.secureTextEntry = !this.state.enableField;
//       this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
//         ? imgPasswordVisible
//         : imgPasswordInVisible;
//     }
//   };

//   btnShowHideImageProps = {
//     source: this.txtInputProps.secureTextEntry
//       ? imgPasswordVisible
//       : imgPasswordInVisible
//   };

//   btnExampleProps = {
//     onPress: () => this.doButtonPressed()
//   };

//   doButtonPressed() {
//     let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
//     msg.addData(
//       getName(MessageEnum.AuthTokenDataMessage),
//       this.state.txtInputValue
//     );
//     this.send(msg);
//   }

//   // Customizable Area Start
//   // Customizable Area End
  
// }
















import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config.web");

export interface Props {
  navigation: any;
  id: string;
  classes:any,
  
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeStep:any;
  toggle:boolean;
  selectType:string;
  
  
  // Customizable Area Start
  firstName:string;
  lastName:string|null;
  mobileNumber:string;
  email:string;
  age:string;
  gender:string;
  address:string;
  pinCode:string;
  district_id:any;
  city_id:any;
  country_id:any;
  companyName:string;
  tradeLicense:string;
  assets:string;
  agent_broker_number:any;
  Profession:any;
  Nationality:any;
  errorMessage:string;
  user_type:string;
  // Customizable Area End
}

interface SS {
  id: any;
  
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
 
  apiFormCallId: any="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeStep:0,
      toggle:false,
      selectType:"",
      firstName:"Sunil",
      lastName:"erjala",
      mobileNumber:"8989898989",
      email:"Sunil123@gmail.com",
      age:"29",
      gender:"Male",
      address:"united emirates",
      pinCode:"45881",
      district_id:"1",
      city_id:"1",
      country_id:"1",
      companyName:"",
      tradeLicense:"",
      assets:"",
      agent_broker_number:"9876543",
      Profession:"",
      Nationality:"",
      errorMessage:"",
      user_type:"",
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    if(localStorage.getItem("stepperCount")){
      let count:any=localStorage.getItem("stepperCount")
      this.setState({activeStep:JSON.parse(count)})
    }
    else{this.setState({activeStep:0})}
  }

  navigateToCustomisableUserProfiles=()=>{
    // let count:any=localStorage.getItem("stepperCount")
    // count=count-1
    localStorage.setItem("stepperCount",JSON.stringify(0))
    this.props.navigation.navigate("CustomisableUserProfiles")
  }

  // navigateToSucessfullyCompleted=()=>{
  //   let count:any=localStorage.getItem("stepperCount")
  //   count=count+1
  //   localStorage.setItem("stepperCount",(count))
  //   this.props.navigation.navigate("SucessfullyCompleted")
  // }


  navigateToNext=()=>{
    if(this.state.selectType=="agent"){
      localStorage.setItem("stepperCount",JSON.stringify(this.state.activeStep+1))
      this.props.navigation.navigate("ProfileSetup")
    }
    else if(this.state.selectType=="other"){
      localStorage.setItem("stepperCount",JSON.stringify(this.state.activeStep+1))
      this.props.navigation.navigate("OtherProfileSetup")

    }
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiFormCallId !== null &&
      this.apiFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  ) {
      this.apiFormCallId = null;

      var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson,"///////////////");
      
      var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

  if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
  } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
  }
  this.parseApiCatchErrorResponse(errorReponse);
}



    // Customizable Area End

  }



  doEmailLogIn():boolean {
    // console.log(this.state.lastName)
    // if (this.state.firstName.length==0||this.state.lastName.length==0||this.state.mobileNumber.length==0||this.state.age.length==0||this.state.gender.length==0||this.state.address.length==0||this.state.pinCode.length==0||this.state.district_id.length==0||this.state.city_id.length==0||this.state.country_id.length==0||this.state.agent_broker_number.length==0
    //   // !this.emailReg.test(this.state.email)
    // ) {
    //   // this.showAlert("Error", configJSON.errorEmailNotValid);
    //   this.setState({errorMessage:"All fields are required"})
    //   return false;
    // }

    // if (this.state.password === null || this.state.password.length === 0) {
    //   // this.showAlert("Error", configJSON.errorPasswordNotValid);
    //   this.setState({errorMessage:""})
    //   return false;
    // }

    // console.log(this.state.firstName)

    // const header = {
    //   "Content-Type": configJSON.validationApiContentType,
    // };'

    const headers ={
      "Content-type":"application/json",
      token:localStorage.getItem("authToken")
    }


    const attrs = {
            first_name: "kanika",
            last_name: "matta",
            phone_number: "9999999999",
            age: "25",
            gender: "female",
            agent_broker_number: "9876543",
            pincode: "45881",
            complete_address: "united emirates",
            city_id:1,
            country_id: 1 ,
            district_id: 1,
            user_type: "agent"
    };

    const data = {
      attributes: attrs
    };

    
    const httpBody = {
      headers:headers,
      data: data
    };

    console.log(httpBody,"data http body")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log(requestMessage.addData,"/////////////")

    this.apiFormCallId = requestMessage.messageId;

    console.log(this.apiFormCallId,"apicallid");
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exampleAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }














  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start
  // Customizable Area End
  
}



