import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import SellDirecetelyController from "./SellDirecetelyController.web";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { home, logo, pdfimg } from "./assets.web";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";

// import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "auto",
    display: "flex",
    // border:"1px solid red",
    "@media (min-height:875px)": {
      height: "100%",
    },
  },
  leftSubContainer: {
    width: "30%",
    height: "auto",
    background: "#5438ba",
    // border:"solid red",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    // height: "100%",
    // "@media (min-width:300px) and (max-width:767px)": {
    //   width: "100%",
    // },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant Garamond",
  },
  headding: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: 500,
    fontFamily: "Cormorant SC",
  },
  addProperty: { color: "white", fontFamily: "Nunito", fontStyle: "normal" },
  nunito: {
    // color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: "1vw",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },
  textField: {
    width: "60%",
    marginBottom: "5%",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid rgb(225,225,225)",
    "&:focus": {
      outline: "2px solid hsl(230,44%,50%)",
    },
  },
  rightFormContainer: {
    marginLeft: "10%",
    marginTop: "13%",
  },
  nextbutton: {
    //   width:"60.2%",
    // color: "white",
    // background: "rgb(84,56,186)",
    // marginLeft: "1%",
    // "&:hover": {
    //   background: "#5438ba",
    // },
  },
  CancleButton: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  asideTextfieldWidth: {
    width: "95%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    height: "40px",
    borderRadius: "5px",
    marginTop: "4%",
    border: "1px solid rgb(219,219,219)",
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "95%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border: "1px solid #c4c4c4",
    marginTop: "4%",
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  asideContainer: {
    width: "40%",
  },
  asideContainer1: {
    width: "40%",
  },
  labelStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "0.8vw",
  },
  ImageContainer: {
    display: "flex",
    justifyContent: "space-around",
  },
  img: {
    width: "40%",
    marginTop: "7%",
  },
  imgRightContainer: {
    width: "40%",
    position: "relative",
  },
  h1Style: {
    fontSize: "1.9vw",
    position: "absolute",
    top: "36%",
  },
  locationContainer: {
    width: "60%",
    heoght: "10%",
    border: "1px solid rgb(231,231,232)",
    borderRadius: "10px",
  },
  cardContainer: {
    width: "60%",
    height: "50px",
    position: "relative",
    marginTop: 0,
    marginBottom: "5%",
    borderRadius: "12px",
    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  UploadContainer: {
    width: "100%",
    height: "100%",
    opacity: 0,
    position: "absolute",
    // display:"none"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  inputLabel: {
    marginTop: "3%",
    marginBottom: "5%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1.9vh",
    marginLeft: "2%",
  },
  uploadedImg: {
    display: "flex",
    width: "90%",
    gap: "1%",
  },
  Otp_textField: {
    display: "flex",
    justifyContent: "space-between",
    width: "60%",
    marginBottom: "5%",
  },
  OTP_bar: {
    width: "22%",
    display: "inline-block",
    textAlign: "center",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "hidden",
    borderBottomColor: "#c4c4c4",
    outline: "none",
    fontSize: "2rem",
  },
};

class SellStep2 extends SellDirecetelyController {
  render() {

    let values: any = {
      accountType: "email_account",
      email: this.state.emailValue,
    };


    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: 17.4576582, lng: 78.3955874 }}
      >
        <Marker
        draggable={true}
        onDragEnd={this.onMarkerDragEnd}
          position={{ lat: 17.4576582, lng: 78.3955874 }}
        >
          <InfoWindow>
            <div>need key to operate</div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    ));

















    const steps = getSteps();
    const handleChange = (e: any) => {
      e.preventDefault()
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");
      this.setState({ otp: this.state.otp + e.target.value });
      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 10) < 4) {
          // Get the next input field
          const nextSibling: any = document.querySelector(
            `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      }
    };

    function getSteps() {
      return ["Category & Address", "Property Details", "Upload Documents"];
    }

    // navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });


    // navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log(position,"position")
    // });

    let { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          {/* left sub container  */}
          <div className={classes.leftSubContainer}>
            <div className={classes.leftContainerData}>
              <img src={logo} className={classes.OmranLogo} />
              <h1 className={classes.LetsStart}>Step 2</h1>
              <p className={classes.addProperty}>
                Add property location & landmark to get to know
              </p>
              <Stepper
                activeStep={this.state.activeStep}
                orientation="vertical"
                style={{ background: "#5438ba" }}
              >
                {steps.map((label, index) => (
                  <Step>
                    <StepLabel>
                      <p className={classes.stepperLabel}>{label}</p>
                    </StepLabel>
                    <StepContent>
                      {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
          {/* right sub container */}
          <div className={classes.rightSubContainer}>
            <div className={classes.rightFormContainer}>
              <h1 className={classes.headding}>
                Post A Sale <br />
                By Owner Listing
              </h1>
              <div className={classes.locationContainer}>
                <div style={{ marginLeft: "5%" }}>
                  <p
                    className={classes.nunito}
                    style={{ fontWeight: "bold", color: "rgb(110,110,110)" }}
                  >
                    YOUR LOCATION
                  </p>
                  <p className={classes.nunito}>1027 Belmont Ave #2A-1</p>
                  <p className={classes.nunito}>Jumeirah ,UAE 11208.</p>
                </div>
              </div>









              {/* <div style={{width:"60%",marginBottom:"2%",marginTop:"5%"}} >
              <MapWithAMarker
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAzqNP4YfqgSpOBJ3W0FRIzJR_1W5dD5g&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `300px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
              </div> */}












              <Button
                style={{
                  color: "white",
                  background: "rgb(175,0,31)",
                  width: "28%",
                }}
              >
                NO, LET ME CHANGE IT
              </Button>
              <Button
                style={{
                  color: "white",
                  background: "rgb(73,165,41)",
                  width: "28%",
                  marginLeft: "4%",
                }}
                // onClick={()=>{this.saveAddress}}
                onClick={() => {
                  this.otp_validation(values);
                }}
              >
                YES ITS CORRECT
              </Button>
              {this.state.titleDeedsDoc.length>0 && (<div
                style={{
                  display: "flex",
                  gap: "2%",
                  marginTop: "5%",
                  width: "60%",
                  justifyContent: "space-between"
                }}
              >
                <div className={classes.uploadedImg}>
                  <img src={pdfimg} />
                  <p style={{ marginLeft: "2%" }}>{this.state.titleDeedsDoc[0]}</p>
                </div>
                <p className={classes.RightContainerAddProperty}>
                  {/* {this.state.img1} */}
                </p>
                <HighlightOffIcon
                  style={{
                    color: "#5438ba",
                    position: "relative",
                    bottom: "5px",
                  }}
                  onClick={() => {
                    this.setState({titleDeedsDoc:""});
                  }}
                />
              </div>)}
              <p className={classes.nunito} style={{ marginTop: "5%" }}>
                ENTER TITLE DEED
              </p>
              {this.state.titleDeedsDoc.length==0 && <Card className={classes.cardContainer}>
                <input
                  type="file"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf/*"
                  className={classes.UploadContainer}
                  onChange={(event: any) => {
                    this.handelImage(event);
                    // this.setState({ img1: event.target.files[0].name });
                    localStorage.setItem("img1", event.target.files[0].name);
                  }}
                />
                <div className={classes.iconContainer}>
                  <label className={classes.inputLabel}>Upload Upload</label>
                  <AddCircleOutlinedIcon
                    style={{ marginTop: "1%", color: "#5438ba" ,boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",borderRadius:"50px"}}
                  />
                </div>
              </Card>}
              <form className={classes.form}>
                <div className={classes.Otp_textField}>
                  <input
                    className={classes.OTP_bar}
                    placeholder="0"
                    type="password"
                    autoComplete="current-password"
                    name="ssn-1"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                  />
                  <input
                    className={classes.OTP_bar}
                    placeholder="0"
                    type="password"
                    autoComplete="current-password"
                    name="ssn-2"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                  />
                  <input
                    className={classes.OTP_bar}
                    placeholder="0"
                    type="password"
                    autoComplete="current-password"
                    name="ssn-3"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                  />
                  <input
                    className={classes.OTP_bar}
                    placeholder="0"
                    type="password"
                    autoComplete="current-password"
                    name="ssn-4"
                    maxLength={1}
                    onChange={(e: any) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </form>
              <p className={classes.errorMessage}>{this.state.errorMessage}</p>
              <Button className={classes.CancleButton}>PREV STEP</Button>
              <Button
                style={{
                  background: "rgb(84,56,186)",
                  color: "white",
                  marginLeft: "1%",
                  marginBottom: "4%",
                }}
                onClick={()=>{this.submitOtp()}}
              >
                NEXT STEP
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(SellStep2);
