import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import ProfileSetupControler from "./ProfileSetupControler";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { logo, home } from "./assets";
import TextField from "@material-ui/core/TextField";
import "./ProfileSetup.css";

let styles: any = {
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "block",
    },
    "@media (min-width:1024px) and (max-width:2000px)": {
      height: "100%",
    },
    "@media (min-height:100px) and  (max-height:656px)": {
      height: "auto",
    },
    "@media (min-height:597px)": {
      height: "100%",
    },
  },
  container2: {
    width: "100%",
    height: "100%",
    display: "flex",
    "@media (min-width:100px) and (max-width:767px)": {
      display: "block",
    },
    "@media (min-height:100px) and  (max-height:536px)": {
      height: "auto",
    },
    "@media (min-height:1063px) and  (max-height:2000px)": {
      height: "100%",
    },
  },
  leftSubContainer: {
    width: "30%",
    background: "#5438ba",
    // border:"solid red"
    "@media (min-width:100px) and (max-width:767px)": {
      display: "none",
    },
  },
  rightSubContainer: {
    width: "70%",
    height: "100%",
    "@media (min-width:300px) and (max-width:425px)": {
      width: "90%",
    },
    // border:"solid black"
  },
  leftContainerData: {
    marginLeft: "2%",
  },
  LetsStart: {
    color: "white",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant SC",
  },
  Category: {
    marginTop: "1%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontFamily: "Cormorant SC",
  },
  addProperty: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
  RightContainerAddProperty: {
    color: "grey",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "3%",
  },

  textField: {
    width: "59%",
    marginBottom: "1%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    },
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
    
  },
  // textField: {
  //   width: "60%",
  //   marginBottom: "1%",
  //   height: "10%",
  //   "@media (min-width:100px) and (max-width:767px)": {
  //     width: "100%",
  //   },
  // },
  rightFormContainer: {
    marginLeft: "10%",
    // marginTop:"14%"
  },
  nextButton: {
    color: "white",
    background: "#5438ba",
    marginLeft: "2%",
    "&:hover": {
      background: "#5438ba",
    },
    // marginBottom: "4%",
  },
  CancleButton: {
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // marginBottom: "4%",
    // width:"90px"
  },
  stepperLabel: {
    color: "white",
  },
  asideTextfield: {
    display: "flex",
    gap: "2%",
    marginBottom: 0,
  },
  asideTextfieldWidth: {
    width: "28%",
    marginBottom: "1%",
    height:"40px",
    borderRadius:"4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    },
    "@media (min-width:100px) and (max-width:767px)": {
      width: "50%",
    },
  },
  asideTextfield2: {
    display: "flex",
    gap: "2%",
    marginBottom: "1%",
  },
  selectTag: {
    width: "29%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    },
    "@media (min-width:100px) and (max-width:767px)": {
      width: "48%",
    },
  },
  selectTag2: {
    width: "29%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    },
    "@media (min-width:100px) and (max-width:767px)": {
      width: "50%",
    },
    "input::placeholder" :{
      color: "#1c87c9",
      opacity: "1"
    }
  },
  HomeImg: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: "30%",
  },
  OmranLogo: {
    width: "20%",
    marginTop: "5%",
  },
  selectTag3: {
    width: "60%",
    height: "42px",
    marginBottom: "1%",
    borderRadius: "4px",
    border:"1px solid rgb(225,225,225)",
    "&:focus":{
      outline:"2px solid hsl(230,44%,50%)"
    },
    "@media (min-width:100px) and (max-width:767px)": {
      width: "100%",
    },
  },
  errorMessage: {
    color: "red",
    fontFamily: "Nunito",
    fontStyle: "normal",
  },
};

class ProfileSetup extends ProfileSetupControler {
  render() {
    const steps = getSteps();

    function getSteps() {
      return ["Choose type", "Create Profile", "Almost Done"];
    }

    let { classes } = this.props;

    return (
      <>
        <img src={home} className={classes.HomeImg} />
        {!this.state.toggle ? (
          <div className={classes.container}>
            {/* left sub container  */}
            <div className={classes.leftSubContainer}>
              <div className={classes.leftContainerData}>
                <img src={logo} className={classes.OmranLogo} />
                <h1 className={classes.LetsStart}>Let's start</h1>
                <p className={classes.addProperty}>
                  Add property location & landmark to get to know
                </p>
                <Stepper
                  activeStep={this.state.activeStep}
                  orientation="vertical"
                  style={{ background: "#5438ba" }}
                >
                  {steps.map((label, index) => (
                    <Step>
                      <StepLabel>
                        <p >{label}</p>
                      </StepLabel>
                      <StepContent>
                        {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            {/* right sub container */}
            <div className={classes.rightSubContainer}>
              <div className={classes.rightFormContainer}>
                <h1 className={classes.Category}>Profile Setup</h1>
                <p className={classes.RightContainerAddProperty}>
                  Add property location & landmark to get to know
                </p>
                <form>
                  <div className={classes.asideTextfield}>
                    <input 
                      className={classes.asideTextfieldWidth}
                      // id="outlined-password-input"
                      placeholder="Rep First name"
                      type="text"
                      value={this.state.firstName}
                      onChange={(e) => {
                        this.setState({ firstName: e.target.value });
                        ;
                      }}
                    />
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="Rep Last name"
                      type="text"
                      value={this.state.lastName}
                      onChange={(e) => {
                        this.setState({ lastName: e.target.value });
                      }}
                    />
                  </div>
                  <input 
                    className={classes.textField}
                    // id="outlined-password-input"
                    placeholder="Mobile number"
                    type="number"
                    value={this.state.mobileNumber}
                    onChange={(e) => {
                      this.setState({ mobileNumber: e.target.value });
                    }}
                  />
                  <br />
                  <div className={classes.asideTextfield}>
                    <input
                      className={classes.asideTextfieldWidth}
                      placeholder="Age"
                      type="number"
                      value={this.state.age}
                      onChange={(e) => {
                        this.setState({ age: e.target.value });
                        this.setState({ user_type: "agent" })
                      }}
                    />
                    <select
                      className={classes.selectTag}
                      onChange={(e) => {
                        this.setState({ gender: e.target.value });
                      }}
                    >
                      <option value="">Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <input
                    className={classes.textField}
                    placeholder="Complete address"
                    type="text"
                    value={this.state.address}
                    onChange={(e) => {
                      this.setState({ address: e.target.value });
                    }}
                  />
                  <br />
                  <input
                    className={classes.textField}
                    placeholder="pincode"
                    type="number"
                    value={this.state.pinCode}
                    onChange={(e) => {
                      this.setState({ pinCode: e.target.value });
                    }}
                  />
                  <br />
                  <select
                    className={classes.selectTag3}
                    onChange={(e:any) => {
                      this.selectCountry(e.target.value);this.setState({country_id:"1"})
                    }}
                  >
                    <option value="reset">Country</option>
                    {this.state.country.map((ele: any) => {
                      return <option onClick={(ele:any)=>{this.selectCountry(ele.id)}}>{ele.attributes.name}</option>;
                    })}
                  </select>



                  <div className={classes.asideTextfield}>
                  <select
                      className={classes.selectTag2}
                      onChange={(e) => {
                        this.selectDistrict(e.target.value)
                      }}
                    >
                      <option value="reset" >City</option>
                      {this.state.city.map((ele: any) => {
                        return <option value={ele.attributes.name}>{ele.attributes.name}</option>;
                      })}
                    </select>
                    <select
                      className={classes.selectTag2}
                      onChange={(e) => {
                        this.setState({ state_id: e.target.value });;this.setState({state_id:"1"})
                      }}
                    >
                      <option value={[]}>District</option>
                      {this.state.district.map((ele: any) => {
                        return <option value={1}>{ele.attributes.name}</option>;
                      })}
                    </select>
                  </div>
                </form>
                <p className={classes.errorMessage}>
                  {this.state.errorMessage}
                </p>
                <Button
                  className={classes.CancleButton}
                  onClick={this.navigateToCustomisableUserProfiles}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.nextButton}
                  onClick={this.nextFields}
                >
                  NEXT STEP
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.container2}>
            {/* left sub container  */}
            <div className={classes.leftSubContainer}>
              <div className={classes.leftContainerData}>
                <img src={logo} className={classes.OmranLogo} />
                <h1 className={classes.LetsStart}>Let's start</h1>
                <p className={classes.addProperty}>
                  Add property location & landmark to get to know
                </p>
                <Stepper
                  activeStep={this.state.activeStep}
                  orientation="vertical"
                  style={{ background: "#5438ba" }}
                >
                  {steps.map((label, index) => (
                    <Step>
                      <StepLabel>
                        <p className={classes.stepperLabel}>{label}</p>
                      </StepLabel>
                      <StepContent>
                        {/* <p style={{color:"white"}} >{getStepContent(index)}</p> */}
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>
            {/* right sub container */}
            <div className={classes.rightSubContainer}>
              <div className={classes.rightFormContainer}>
                <h1 className={classes.Category}>Profile Setup</h1>
                <p className={classes.RightContainerAddProperty}>
                  Add property location & landmark to get to know
                </p>
                <form>
                  <input
                    className={classes.textField}
                    placeholder="Agent Code"
                    value={this.state.agent_broker_number}
                    onChange={(e) => {
                      this.setState({ agent_broker_number: e.target.value });
                    }}
                  />
                  <br />
                  <input 
                    className={classes.textField}
                    placeholder="Profession"
                    type="text"
                    value={this.state.Profession}
                    onChange={(e) => {
                      this.setState({ Profession: e.target.value });
                    }}
                  />
                  <br />
                  <input
                    className={classes.textField}
                    placeholder="Nationality"
                    type="text"
                    value={this.state.Nationality}
                    onChange={(e) => {
                      this.setState({ Nationality: e.target.value });
                    }}
                  />
                  <br />
                  <select
                    className={classes.selectTag3}
                    onChange={(e:any)=>{this.setState({assets:e.target.value})}}
                  >
                    <option value="" >Choose assets</option>
                    <option value="land" >Land</option>
                    <option value="agricultural" >agricultural</option>
                    <option value="commercial" >commercial</option>
                    <option value="industrial" >industrial</option>
                    <option value="mix_use" >mix_use</option>
                    <option value="plant_equipment" >plant_equipment</option>
                    <option value="residential" >residential</option>
                    <option value="specialized_building" >specialized_building</option>
                  </select>
                  {/* <TextField
                    className={classes.textField}
                    // id="outlined-password-input"
                    label="Assets"
                    type=""
                    autoComplete="current-password"
                    variant="outlined"
                    value={this.state.assets}
                    onChange={(e) => {
                      this.setState({ assets: e.target.value });
                    }}
                  /> */}
                  <br />
                </form>
                <p className={classes.errorMessage}>
                  {this.state.errorMessage}
                </p>
                <Button
                  className={classes.CancleButton}
                  onClick={() => {
                    this.setState({ toggle: !this.state.toggle });
                    this.setState({ activeStep: this.state.activeStep - 1 });this.setState({country_id:""});this.setState({city_id:""});this.setState({state_id:""})
                  }}
                >
                  Previous step
                </Button>
                <Button className={classes.nextButton} onClick={this.saveData}>
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withStyles(styles)(ProfileSetup);









