import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  FlatList,
  ActivityIndicator,
} from "react-native";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import Favorite from '@material-ui/icons/Favorite';
// import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
// Customizable Area End
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DashboardController, { Props } from "./DashboardController.web";
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/styles";
import {
  firstimg,
  logo,
  profile,
  search,
  secondimg,
  at,
  thirdslide,
  hex1,
  hex2,
  hex3,
  hex4,
  hex5,
  hex6,
  hex7,
  dots,
  circle,
  human1,
  human2,
  human3,
  spher,
  human4,
  human5,
  home1,
  home2,arrow,facebook,
  // insta,
  twitter,
  // youtube,
  linkdin,img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,play,hexa
} from "./assets.web";
import { Divider } from "@material-ui/core";
import "./home.css"
const styles: any = {
  container: {
    width: "100%",
    height: "auto",
  },
  firstimg: {
    width: "100%",
    objectFit: "cover",
  },
  header: {
    height: "20%",
    width: "100%",
    // border:"1px solid red",
    display: "flex",
    flexWrap:"wrap",
    justifyContent: "space-between",
    top: 0,
    zIndex: 1,
    position: "fixed",
    marginTop:"1%",
    "@media (max-width:930x)":{
      justifyContent: "space-evenly"
    },
    "&:window.scrollY(100)":{
      background:"solid red"
    },
    "@media window.scrollY(100)":{
      background:"solid red"
    },
  },
  headerFirstBlock: {
    display: "flex",
    gap: "4%",
    // marginTop: "1%",
    width: "55%",
    marginLeft: "2%",
    // "@media (max-width:840px)":{
    //   display:"block",
    //   marginTop: 0
    // }
  },
  headerSecondBlock: {
    display: "flex",
    gap: "5%",
    // marginTop: "1%",
    marginRight: "2%",
    width: "28%",
    // "@media (max-width:840px)":{
    //   display:"block",
    //   position:"relative",
    //   bottom:"30%"
    // }
    justifyContent:"flex-end"
  },
  startvaluation: {
    width: "50%",
    color: "white",
    height: "31%",
    border: "1px solid #c7c7c7",
    borderRadius: "10px",
    background: "#c7c7c7",
    marginTop: "6%",
  },
  startvaluationpara: {
    background: "rgb(98,146,195)",
    color: "white",
    border: "rgb(98,146,195)",
    borderRadius: "25px",
    height: "35px",
    marginTop: "2%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize:"0.8vw"
  },
  propertypara: {
    position: "absolute",
    marginTop: 0,
    marginBottom: 0,
    bottom: "1%",
    left: "6%",
    color: "white",
    fontSize: "6vw",
    fontFamily:"Cormorant SC",
    fontWeight:100
  },
  secondSlide: {
    height: "auto",
    position: "relative",
    bottom: "5px",
    background: "rgb(22,21,29)",
  },
  secondSlideSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:999px)":{
      display:"block",
    }
  },
  secondFirstContainer: {
    width: "60%",
    display: "flex",
    marginLeft: "6%","@media (max-width:999px)":{
      display:"block",
    }
  },
  secondSlidesecondContainer: {
    width: "45%",
    height: "fit-content",
  },
  secondSlideButton: {
    background: "transparent",
    color: "rgb(96,95,100)",
    border: "1px solid dimgray",
    borderRadius: "25px",
    height: "35px",
    marginBottom: "2%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  searchBox: {
    width: "72%",
    position: "relative",
    height: "40px",
    borderRadius: "30px",
    border: "none",
    bottom: "18px",
    marginLeft: "12%",
    "@media (max-width:999px)":{
      position:"static",
    },
    // "&:focus":{
    //   border:"none"
    // }
  },
  secondSlidepara: {
    fontSize: "2.2vw",
    marginBottom: 0,
    marginTop: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    // fontWeight: "bold",
    marginLeft: "50%",
    color: "rgb(116,139,111)",
  },
  secondSlidepara1: {
    fontSize: "2.2vw",
    marginBottom: 0,
    marginTop: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    // fontWeight: "bold",
    color: "rgb(211,209,213)",
  //   "@media (max-width:722px)":{
  //     display:"block"
  // }
  },
  secondSlideAppartmentPara: {
    color: "white",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "2px",
    marginTop: 0,
    marginLeft: "50%",
    fontSize: "0.7rem",
  },
  secondSlideAppartmentPara1: {
    color: "rgb(157,156,163)",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "2px",
    marginTop: 0,
    fontWeight:100,
    // marginLeft:"50%",
    fontSize: "0.9rem",
  },
  secondSlideAppartmentPara2: {
    color: "rgb(157,156,163)",
    fontFamily: "Nunito",
    fontStyle: "normal",
    marginBottom: "2px",
    marginTop: 0,
    fontWeight:100,
    // marginLeft:"50%",
    fontSize: "1vw",
    paddingBottom:"7%"
  },
  thirdSlide: {
    height: "auto",
    width: "100%",
    position: "relative",
    bottom: "7px",
    // bottom:"7px",
    background: "rgb(22,21,29)",
  },
  thirdSlideSubContainer: {
    // display: "flex",
    position: "relative",
    justifyContent: "space-between",
    // bottom: "7px",
  },
  thirdSlideimgdiv: {
    display: "flex",
    gap: "5px",
    position: "absolute",
    top: 0,
    width: "100%",
    height: "-webkit-fill-available",
    justifyContent: "space-between","@media (max-width:999px)":{
      display:"block",
    }
  },
  line: {
    width: "1px",
    backgroundcolor: "silver",
    height: "100%",
    float: "left",
    border: "2px ridge silver",
    borderRadius: "2px",
  },
  thirdSlideFirstContainer: {
    color: "white",
    position: "relative",
    width: "25%",
    marginLeft: "2%",
  },
  thirdSlideFirstContainerpara: {
    position: "absolute",
    bottom: "3%",
    fontFamily: "Nunito",
    fontStyle: "normal",
    color:"rgb(211,209,213)",
    "@media (max-width:999px)":{
      display:"none",
    }
  },
  thirdSlideFirstContainerpara1: {
    position: "absolute",
    bottom: "0",
    fontFamily:"Cormorant SC",
    fontWeight:100,
    "@media (max-width:999px)":{
      display:"none",
    }
  },
  fourthSlide: {
    width: "100%",
    hetght: "auto",
    background: "rgb(22,21,29)",
    position: "relative",
    bottom: "7px",
  },
  fourthSlideSubContainer: {
    width: "100%",
    textAlign: "center",
    // position:"absolute"
  },
  hex: {
    width: "10%",
  },
  hexagonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    width:"90%",
    marginLeft:"5%",
    // "@media (max-width:999px)":{
    //   display:"block",
    // }
  },
  hexagonsecondContainer: {
    width:"76%",
    display: "flex",
    justifyContent: "center",
    marginTop: "5%",
    gap: 0,
    paddingBottom: "10%",
    marginLeft:"12%",
    // "@media (max-width:999px)":{
    //   display:"block",
    // }
  },
  fifthSlide: {
    width: "100%",
    height: "auto",
    // position:"absolute"
  },
  fifthSubContainer: {
    position: "relative",
  },
  analyticsContainer: {
    display: "flex",
    gap: "2%","@media (max-width:999px)":{
      display:"block",
    }
  },
  leftGraph: {
    width: "50%",
    height: "300px",
    border: "1px solid red",
  },
  rightGraph: {
    width: "50%",
    height: "300px",
    border: "1px solid blue",
  },
  dynamicDataContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
    gap: "1%",
    paddingBottom: "5%","@media (max-width:999px)":{
      display:"block",
    }
  },
  para: {
    fontSize: "1.6rem",
    fontWeight: 100,
  },
  mainHeadding: {
    fontSize: "3rem",
    fontWeight: 100,
  },
  sixtSlide: {
    width: "100%",
    height: "auto",
    background: "rgb(22,21,29)",
    position: "relative",
    bottom: "7px",
  },
  sixthSubContainer: {},
  starsContainer: {
    display: "flex",
    paddingTop: "5%",
    width: "100%",
    height: "auto",
    gap: "2%",
    // border:"1px solid red",
    justifyContent: "flex-end",
    "@media (max-width:600px)":{
      display:"block",
    }
  },
  starsContainer1: {
    display: "flex",
    paddingTop: "5%",
    width: "90%",
    height: "auto",
    gap: "2%",
    // border:"1px solid red",
    justifyContent: "flex-end",
    "@media (max-width:722px)":{
      display:"block"
  }
  },
  imgContainer: {
    width: "30%",
    position: "relative",
    paddingBottom: "10%",
    "&:hover":{
      // border:"3px solid red",
      // transform:"scale(1.2)"
    }
  },
  starimg: {
    width: "100%",
  },
  para1: {
    color: "rgb(84,83,90)",
    marginTop: 0,
    marginBottom: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 200,
  },
  h3Style: {
    color: "rgb(211,209,213)",
    fontWeight: 100,
    fontFamily:"Cormorant SC",
  },
  verticalLetter:{
    fontWeight:100,position:"absolute",bottom:"30%",transform: "rotate(-90deg)","@media (max-width:897px)":{
      display:"none"
    },
  },
  verticalLetter1:{
    fontWeight:100,transform: "rotate(-90deg)",position:"absolute",bottom:"37%","@media (max-width:897px)":{
      display:"none"
    },
  },
  arrow:{
    width:"35%",
    position:"absolute",
    bottom:"25%",
    left:"21%"
  },
  seventhSlideimgContainer: {
    display: "flex",
    paddingTop: "5%",
    width: "100%",
    height: "auto",
    gap: "2%",
    // border:"1px solid red",
    // justifyContent: "space-evenly","@media (max-width:999px)":{
    //   display:"block",
    // }
  },
  eightSlide:{
    background:"rgb(244,244,244)",
    textAlign:"center"
  },
  ninthSlide:{
    background:"black",
    width:"100%"
  },
  ninthSlideSubContainer:{
    position:"relative",
    width:"75%",
    margin:"auto",
    paddingTop:"5%"
  },
  ninthSlideHeadder:{
    width:"100%",
    display:"flex",
    height:"auto",
    justifyContent:"space-between",
    paddingTop:"5%",
    "@media (max-width:999px)":{
      display:"block",
    }
  },
  tableContainer:{
    width:"80%",
    margin:"auto"
  },
  tableHead:{
    width:"20%",
    textAlign:"start",
    color:"white"
  },
  tableHead1:{
    width:"20%",
    textAlign:"center",
    color:"white"
  },
  tableHead2:{
    width:"20%",
    textAlign:"end",
    color:"white"
  },
  details:{
    width:"22%",
    // height:"auto"
  },
  tenthSlide:{
    background:"rgb(25,24,31)",
    width:"100%"
  },
  tenthGridContainer:{
    display:"grid",
    gridTemplateColumns:"auto auto auto auto auto auto auto",
    paddingTop:"5%",
    placeItems:"center"
  },
  headding:{
    fontFamily:"Cormorant SC",
    fontWeight:100
  },
  hexContainer:{position:"relative",width:"30%",
  textAlign:"center",
  "@media (max-width:999px)":{
    width:"100%",
  }},
  hexImg1:{width: "50%"},
  heximg2:{position:"absolute",left:"40%",top:"28%",width:"21%"},
  hexPara:{
    position:"relative",
    color:"white",
    bottom:"30%",
    // left:"8%",
    fontSize:"0.8vw",
    
  },
  MasterMindPara:{
    fontFamily:"Cormorant SC",
    fontWeight:100, color: "white",fontSize:"6vw",marginTop:0,marginBottom:0 ,
    // "@media (max-width:999px)":{
    //   display:"block",
    // }
  },
  SocialMediaContainer:{
    display:"flex",
    "@media (max-width:722px)":{
      display:"block"
  }
},
footerDetails:{
  display:"flex",flexWrap:"wrap",width:"100%",justifyContent:"space-between",paddingTop:"5%",paddingBottom:"5%",
  "@media (max-width:480px)":{
    display:"block"
}
},
exploreMore:{ color: "white",position:"absolute",bottom:"30%","@media (max-width:846px)":{
  position:"initial"
} },
logoContainer:{
  width: "4%",position:"absolute",left:"49%" ,"@media (max-width:935px)":{
    display:"none"
  } 
}
};
// Customizable Area End

class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props;

    const discoverOvaluate=["BUY","Sell","Rent"]
    const others=["FAQ","Location","News","Blog"]
    const about_us=["About us","Advertise","Career","Clients"]
    const ovaluate=["Data points","Jobs @ Ovaluate","The Platform","Features","Standards"]
    const img=[img1,img2,img3,img4,img5,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img1,img2,img3,img4,img5,img7,img8,img9,img10,img11,img12,img13]

    return (
      <div className={classes.container}>
        {/* first page */}
        <div className={classes.header}>
          <div className={classes.headerFirstBlock}>
          <p style={{ color: "white",width:"11%",fontSize:"1vw" }}>About us</p>
            <p style={{ color: "white",fontSize:"1vw" }}>BUY</p>
            <p style={{ color: "white",fontSize:"1vw" }}>Sell</p>
            <p style={{ color: "white",fontSize:"1vw" }}>Rent</p>
            <p style={{ color: "white",fontSize:"1vw" }}>Advertise</p>
            <p style={{ color: "white",fontSize:"1vw" }}>Trends</p>
          </div>
          <img src={logo} className={classes.logoContainer} />
          <div className={classes.headerSecondBlock}>
            {/* <div className={classes.startvaluation}>
                <p className={classes.startvaluationpara}>START VALUATION</p>
              </div> */}
            <button className={classes.startvaluationpara} onClick={()=>{this.props.navigation.navigate("Step1")}} >
              START VALUATION
            </button>
            <img
              src={search}
              style={{ width: "7%", height: "20%", marginTop: "3%" }}
            />
            <img
              src={profile}
              style={{ width: "7%", height: "20%", marginTop: "3%" }}
            />
            <div>
              <p style={{ color: "white", marginTop: "43%",fontSize:"1vw" }}>
                EN/AR
              </p>
            </div>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <img src={firstimg} style={{ width: "100%" }} />

          <h1 className={classes.propertypara}>Property</h1>
        </div>
        {/* end of first page */}

        {/* second slide start */}
        <div className={classes.secondSlide}>
          <div className={classes.secondSlideSubContainer}>
            <div className={classes.secondFirstContainer}>
              <h1
                style={{
                  color: "white",
                  marginTop: 0,
                  marginBottom: 0,
                  display: "inlineBlock",
                  fontSize: "6vw",
                  fontFamily:"Cormorant SC",
                  fontWeight:100
                }}
              >
                Valuation
              </h1>
              <h1
                style={{
                  color: "rgb(165,214,145)",
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: "6vw",
                  // fontFamily:"Cormorant Garamond, serif",
                  fontFamily: "Cormorant Garamond, serif",
                  fontStyle:"italic",fontWeight:100,marginLeft:"1%"
                }}
              >
                Check
              </h1>
            </div>
            <div className={classes.secondSlidesecondContainer}>
              <input
                type="text"
                placeholder="Search"
                className={classes.searchBox}
              />
              <p style={{ color: "rgb(145,143,152)", marginLeft: "12%" }}>SUGGESTIONS</p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "3%",
                  width: "65%",
                  marginLeft: "12%",
                }}
              >
                <button className={classes.secondSlideButton}>
                  START VALUATION
                </button>
                <button className={classes.secondSlideButton}>
                  NEIGHBOUR HOOD
                </button>
                <button className={classes.secondSlideButton}>CITY</button>
                <button className={classes.secondSlideButton}>
                  INVESTEMENT
                </button>
                <button className={classes.secondSlideButton}>AGENT</button>
              </div>
            </div>
          </div>
          <img src={play} style={{position:"absolute",top:"6%",left:"14%",width:"7%"}}/>
          <img src={at} style={{position:"relative",right:0,width:"4%"}} />
          <div style={{ marginTop: "10%" }}>
            <p className={classes.secondSlidepara}>LUXURY FAMILY RESIDENCES</p>
            <br />
            <p className={classes.secondSlidepara}>WITH A WELL-DEVELOPED</p>
            <br />
            <div style={{ display: "flex", height: "auto", gap: "1%" }}>
              <p className={classes.secondSlidepara}>INFRASTRUCTURE</p>
              <p className={classes.secondSlidepara1}>WITHIN A MINUTE</p>
            </div>
            <br />
            <p className={classes.secondSlidepara}>OF VICTORY PARK</p>
            <div style={{ marginTop: "8%",marginLeft:"50%" }}>
            <p className={classes.secondSlideAppartmentPara1}>
              APARTMENTS FROM 64 TO 301 SQ.M WITH TERRACES
            </p>
            <p className={classes.secondSlideAppartmentPara1}>
              AND PATIOS IN A CLOSED TERRITORY,IN ONE OF THE BEST
            </p>
            <p className={classes.secondSlideAppartmentPara1}>
              RESIDENTIAL AREAS IN MOSCOW
            </p>
          </div>
          </div>
          
          <h3 style={{ color: "rgb(211,209,213)",fontSize:"2vw",paddingBottom:"1%", marginTop: "10%", marginBottom: 0,fontFamily:"Cormorant SC",fontWeight:100 }}>
            Latest Projects
          </h3>
          <p className={classes.secondSlideAppartmentPara2}>
            DISCOVER A PLACE WHERE YOU'LL LOVE TO LIVE
          </p>
          <img
            src={secondimg}
            style={{ position: "absolute", right: "1%", bottom: "15%" }}
          />
        </div>
        {/* second slide end */}

        {/* third slide start */}
        <div className={classes.thirdSlide}>
          <div className={classes.thirdSlideSubContainer}>
            <img src={thirdslide} style={{ width: "100%" }} />
            <div className={classes.thirdSlideimgdiv}>
              <div className={classes.thirdSlideFirstContainer}>
                <p className={classes.thirdSlideFirstContainerpara}>
                  FOLKART COUNTRY URLA
                </p>
                <h3 className={classes.thirdSlideFirstContainerpara1}>
                  Inspiration From Nature!
                </h3>
              </div>
              <Divider orientation="vertical" />
              <div className={classes.thirdSlideFirstContainer}>
                <p className={classes.thirdSlideFirstContainerpara}>
                  FOLKART VEGA
                </p>
                <h3 className={classes.thirdSlideFirstContainerpara1}>
                  Gate To Heaven
                </h3>
              </div>
              <Divider orientation="vertical" />
              <div className={classes.thirdSlideFirstContainer}>
                <p className={classes.thirdSlideFirstContainerpara}>
                  FOLKART BOLYIK IN CESME
                </p>
                <h3 className={classes.thirdSlideFirstContainerpara1}>
                  Life Has Bengun
                </h3>
              </div>
              <Divider orientation="vertical" />
              <div className={classes.thirdSlideFirstContainer}>
                <p className={classes.thirdSlideFirstContainerpara}>
                  AT FOLKART LINE
                </p>
                <h3 className={classes.thirdSlideFirstContainerpara1}>
                  Comfortable Living
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* third slide end */}

        {/* fourth slide start */}
        <div className={classes.fourthSlide}>
          <div className={classes.fourthSlideSubContainer}>
            <h1 style={{ color: "rgb(232,232,235)", marginTop: "0", paddingTop: "10%",fontFamily:"Cormorant SC",fontWeight:100 ,fontSize:"3vw"}}>
              Luxury Features
            </h1>
            <p
              style={{
                color: "rgb(157,156,163)",
                fontFamily: "Nunito",
                fontStyle: "normal",
              }}
            >
              WE HAVE EVERYTHING YOOU ARE LOOKING FOR
            </p>
          </div>
          <div className={classes.hexagonContainer}>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex1} className={classes.heximg2} /><p style={{position:"relative",color:"white",bottom:"29%",fontSize:"0.8vw",}} >Compare</p>
            </div>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex2} className={classes.heximg2} /><p className={classes.hexPara} >INVESTEMENT MAP</p>
            </div>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex3} className={classes.heximg2} /><p className={classes.hexPara} >MARKET INSIGHTS</p>
            </div>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex4} className={classes.heximg2} /><p style={{position:"relative",color:"white",bottom:"27%",fontSize:"0.8vw"}}  >MARKETPLACE</p>
            </div>
          </div>
          {/* second hexagon container */}
          <div className={classes.hexagonsecondContainer}>
          <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex5} className={classes.heximg2} /><p style={{position:"relative",color:"white",bottom:"28%",fontSize:"0.8vw"}} >DYNAMIC DATA</p>
            </div>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex6} className={classes.heximg2} /><p style={{position:"relative",color:"white",bottom:"28%",fontSize:"0.8vw"}} >MULTI METHOD</p>
            </div>
            <div className={classes.hexContainer}><img src={hexa} className={classes.hexImg1}  />
            <img src={hex7} className={classes.heximg2} /><p style={{position:"relative",color:"white",bottom:"28%",fontSize:"0.8vw"}} >COLLABORATIVE</p>
            </div>
          </div>
        </div>
        {/* fourth slide end */}

        {/* fifth slide start */}
        {/* <div className={classes.fifthSlide}>
          <div className={classes.fifthSubContainer}>
            <img src={dots} style={{ width: "20%" }} />
            <img
              src={circle}
              style={{
                width: "20%",
                position: "absolute",
                top: "4%",
                right: "4%",
              }}
            />
            <div style={{ width: "80%", marginLeft: "10%" }}>
              <p>MARKET INSIGHT</p>
              <h1>Analytics</h1>
              <div className={classes.analyticsContainer}>
                <div className={classes.leftGraph} />
                <div className={classes.rightGraph} />
              </div>
            </div>

            <div className={classes.dynamicDataContainer}>
              <div style={{ border: "1px solid red" }}>
                <p style={{ fontFamily: "Nunito", fontStyle: "normal" }}>
                  SIMPLE SOLUTIONS FOR COMPLEX CHALLENGES
                </p>
                <h1 className={classes.mainHeadding}>Our Speciality</h1>
                <div>
                  <h1 className={classes.para}>DYNAMIC DATA</h1>
                  <h1 className={classes.para}>REAL TIME</h1>
                  <h1 className={classes.para}>MULTI METHOD</h1>
                  <h1 className={classes.para}>BILINGUAL</h1>
                  <h1 className={classes.para}>SURVEYING</h1>
                  <h1 className={classes.para}>COLLABORATIVE</h1>
                  {/* <h1>MACHINE LEARNING</h1> */}
        {/* <h1 className={classes.para}>MULTI SECTOR</h1>
                </div>
              </div>
              <div style={{ width: "30%", border: "1px solid blue" }}>
                <h3 style={{ fontWeight: 100 }}>DYNAMIC DATA</h3>
                <p style={{ fontWeight: 100 }}>
                  Using Unique Learning Algorithms We Extract Data Base On
                  Various Criteria To Make A more Accurate Prediction For
                  Valuation
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* fifth slide end */}

        {/* sixth slide start */}

        <div className={classes.sixtSlide}>
          <div className={classes.sixtSubcontainer}>
            <img
              src={spher}
              style={{ position: "absolute", left: "-8%", top: "17%" }}
            />
            <div style={{display:"flex",justifyContent:"flex-end",flexWrap:"wrap"}} >
            <div className={classes.starsContainer1}>
              <div style={{ width: "25%",position:"relative" }}>
                <h3 style={{ color: "white" }}>HIDDEN GEM</h3>
                <h1 style={{ color: "white",fontFamily:"Cormorant SC",fontWeight:100 }}>Ovaluate Stars</h1>
                <p style={{ color: "grey" }}>
                  {" "}
                  We offer services that cover all aspects of the desgine and
                  build process, engage the client at every step to ensure the
                  best experience possible
                </p>
                <p className={classes.exploreMore}>EXPLORE ALL TEAM</p>
              </div>
              <div className={classes.imgContainer}>
                <img src={human1} className={classes.starimg} />
                <h3 className={classes.h3Style}>Indira Shree</h3>
                <p className={classes.para1}>Co-Founder</p>
                <p className={classes.para1}>BUSINESS DEVELOPEMENT</p>
              </div>
              <div className={classes.imgContainer}>
                <img src={human2} className={classes.starimg} />
                <h3 className={classes.h3Style}>Olivia Arribas</h3>
                <p className={classes.para1}>Co-Founder</p>
                <p className={classes.para1}>BUSINESS DEVELOPEMENT</p>
              </div>
              <div className={classes.imgContainer}>
                <img src={human3} className={classes.starimg} />
                <h3 className={classes.h3Style}>Sofietje Boksem</h3>
                <p className={classes.para1}>Co-Founder</p>
                <p className={classes.para1}>BUSINESS DEVELOPEMENT</p>
              </div>
              {/* <div className={classes.imgContainer} >
                <img src={human3} className={classes.starimg} />
                 <h3 className={classes.h3Style}>Sofietje Boksem</h3>
<p className={classes.para1}>Co-Founder</p>
<p className={classes.para1}>BUSINESS DEVELOPEMENT</p>
              </div> */}
            </div>
            </div>
            <h1 className={classes.headding} style={{color:"transparent", fontSize: "8.7vw",WebkitTextStrokeWidth:"2px",WebkitTextStrokeColor:"rgb(52,51,60)" }}>
              ty - Integrity - Experience
            </h1>
            <div style={{marginLeft:"4%"}} >
            <h1 className={classes.MasterMindPara}>Meet The</h1>
            <h1 className={classes.MasterMindPara}  style={{ color: "rgb(163,214,146)"}}>Mastermindes</h1>
            <h1 className={classes.MasterMindPara}>Behind Ovaluate</h1>
            </div>
          </div>

          <div className={classes.starsContainer}>
            <div className={classes.imgContainer}>
              <img src={human5} className={classes.starimg} />
            </div>
            <div style={{ width: "25%" }}>
              <h2 className={classes.headding}  style={{ color: "rgb(211,209,213)" }}>Omran Yourself</h2>
              <p style={{ color: "rgb(84,83,90)", paddingTop: "20%",fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 100, }}>
                RICS Certified valuer with more than 19 years of real state
                experience in Dubai, Abu Dhabi, Al Ain and Sharjah
              </p>
              <p style={{ color: "rgb(84,83,90)", paddingTop: "20%" ,fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 100,}}>GET IN TOUCH</p>
              <hr />
            </div>
            <div className={classes.imgContainer}>
              <img src={human4} className={classes.starimg} />
            </div>
          </div>
        </div>
        {/* sixth slide end */}

        {/* sevent Slide start */}
        <div className={classes.fifthSlide}>
          <div className={classes.fifthSubContainer}>
            <div className={classes.seventhSlideimgContainer}>
              <div style={{ width: "25%",position:"relative" }}>
                <img src={arrow} className={classes.arrow} />
                <h1 className={classes.verticalLetter} >NEWS</h1><br/>
                <h1 className={classes.verticalLetter1}>AND OFFERS</h1>
              </div>
              <div className={classes.imgContainer}>
                <img src={home1} className={classes.starimg} />
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    fontSize:"2vw"
                  }}
                >
                  NEWS
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "4%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    marginTop: "12%",
                    fontSize:"1.5vw"
                  }}
                >
                  YANA KUSHNER, WILLIAM AND OLGA LAMBERTI, YULIA AKIMOVA AND
                  PAVEL BOBROV IN A NEW SPECIAL PROJECT OF HAPPER,S BAZAAR
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    bottom: "26%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    fontSize:"2vw"
                  }}
                >
                  04
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    right: "10%",
                    bottom: "26%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    fontSize:"2vw"
                  }}
                >
                  AUG
                </p>
              </div>
              <div className={classes.imgContainer}>
                <img src={home2} className={classes.starimg} />
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    top: 0,
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    fontSize:"2vw"
                  }}
                >
                  NEWS
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    top: "4%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    marginTop: "12%",
                    fontSize:"1.5vw"
                  }}
                >
                  YANA KUSHNER, WILLIAM AND OLGA LAMBERTI, YULIA AKIMOVA AND
                  PAVEL BOBROV IN A NEW SPECIAL PROJECT OF HAPPER,S BAZAAR
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    bottom: "26%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    marginLeft: "10%",
                    fontSize:"2vw"
                  }}
                >
                  04
                </p>
                <p
                  style={{
                    color: "white",
                    position: "absolute",
                    right: "10%",
                    bottom: "26%",
                    fontFamily: "Nunito",
                    fontStyle: "normal",
                    fontWeight: 100,
                    fontSize:"2vw"
                  }}
                >
                  AUG
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* seventh slide end */}

        {/* Eight slide start */}

        <div className={classes.eightSlide} >
            <div style={{position:"relative",paddingBottom:"5%",paddingTop:"5%"}} >
            <h1 className={classes.headding}  >We'd love to hear your ideas</h1>
                <h1 className={classes.headding}>Let's grow together</h1>
                <TextField error id="standard-error" label="YOUR NAME" style={{width:"50%",paddingBottom:"1%"}} /><br/>
                <TextField error id="standard-error" label="EMAIL" style={{width:"50%",paddingBottom:"1%"}}  /><br/>
                <div style={{width:"50%",margin:"auto",textAlign:"start"}} >
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={state.checkedB}
                      // onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="I ACCEPT THE TERMS FOR PROCESSING THE DATA"
                />
                </div>
                <button style={{width:"80px",height:"80px",borderRadius:"50px",border:"none",background:"rgb(82,55,184)"}} ><ArrowForwardIcon style={{color:"White"}} /></button>

            </div>
        </div>

        {/* Eight slide end */}


        {/* ninth Slide start */}

        <div className={classes.ninthSlide} >

          
                  <div className={classes.ninthSlideSubContainer}>
                      <div className={classes.ninthSlideHeadder} >
                        <div style={{display:"flex",gap:"5%"}} >
                        <img src={logo} style={{width:"25%"}} />
                        <div><h3 className={classes.headding}  style={{color:"rgb(211,209,213)",marginBottom:0}} >Reinvent your space,</h3><br/>
                        <h3 className={classes.headding}  style={{color:"rgb(211,209,213)",marginTop:0}} > with us.</h3></div>
                        
                        </div>
                        <div className={classes.SocialMediaContainer} >
                        <p style={{color:"rgb(211,209,213)",width:"16%",marginTop:"7%"}} >Follow us</p>
                        <img src={facebook} />
                        <img src={facebook} />
                        <img src={facebook} />
                        {/* <img src={insta} /> */}
                        <img src={twitter} />
                        {/* <img src={youtube} /> */}
                        <img src={linkdin} />
                        </div>
                      </div>
                      {/* <table className={classes.tableContainer} >
                        <thead>
                          <tr>
                            <th className={classes.tableHead}>Discovet Ovaluate</th>
                            <th className={classes.tableHead1}>Others</th>
                            <th className={classes.tableHead1}>About us</th>
                            <th className={classes.tableHead2}>Ovaluate</th>
                          </tr>
                        </thead>
                      </table> */}
                      {/* first details */}
                      <div className={classes.footerDetails} >
                        <div className={classes.details} >
                            <h3 className={classes.h3Style} >Discover Ovaluate</h3>
                            {discoverOvaluate.map((ele:any)=>{
                              return <p className={classes.para1}>{ele}</p>
                            })}
                        </div>
                        <div className={classes.details} >
                            <h3 className={classes.h3Style}>Others</h3>
                            {others.map((ele:any)=>{
                              return <p className={classes.para1}>{ele}</p>
                            })}
                        </div>
                        <div className={classes.details} >
                            <h3 className={classes.h3Style}>About us</h3>
                            {about_us.map((ele:any)=>{
                              return <p className={classes.para1}>{ele}</p>
                            })}
                        </div>
                        <div className={classes.details} >
                            <h3 className={classes.h3Style}>Ovaluate</h3>
                            {ovaluate.map((ele:any)=>{
                              return <p className={classes.para1} >{ele}</p>
                            })}
                        </div>
                      </div>
                      <hr/>
                      {/* <Divider/> */}
                      <div className={classes.ninthSlideHeadder} >
                        <p className={classes.para1}>Contact</p>
                        <div><p className={classes.para1}>Phone</p><br/><p className={classes.para1}>+351 213 658 907</p></div>
                        <div><p className={classes.para1}>Email</p><br/><p className={classes.para1}>info@Ovaluate.com</p></div>
                        <button style={{height:"50px",width:"100px"}} >Contact us</button>
                      </div>
                      
                  </div>
        </div>

        {/* ninth slide end */}


        {/* 10 slide start */}
        <div className={classes.ninthSlide}>
          <div className={classes.ninthSlideSubContainer}>
            <div className={classes.tenthGridContainer}  >
                {img.map((ele:any)=>{
                  return <img src={ele} style={{width:"75%",marginBottom:"20%"}} />
                })}
            </div>
            <hr/>
            <div style={{display:"flex",justifyContent:"space-between"}} >
              <div>
              <p className={classes.para1} style={{color:"rgb(112,110,118)",fontWeight:"bold"}} >@Copyright 2021 - Ovaluate</p><br/>
              <p className={classes.para1} style={{color:"rgb(112,110,118)",fontWeight:"bold"}}>
                BACK TO TOP
              </p>
              </div>
              <div style={{display:"flex",width:"34%",gap:"8%"}} >
              <p className={classes.para1} style={{color:"rgb(112,110,118)",fontWeight:"bold"}}>Terms and Conditions</p>
              <p className={classes.para1} style={{color:"rgb(112,110,118)",fontWeight:"bold"}}>Privacy Cookies Policy</p>
              </div>
            </div>
          </div>
        </div>
        {/* 10 slide end */}
      </div>
    );
  }
}

export default withStyles(styles)(Dashboard);
